<template>
  <a-result
      status="success"
      title="已提交">
  </a-result>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
