<template>
  <a-layout-content class="content">
    <div class="header">
      <img src="../assets/logo_shadow.png" class="logo"/>
      <h3 style="color:white">欢迎加入勤工助学平台</h3>
      <h4 style="color:white">请如实填写以下信息，提交后业务员会及时联系您</h4>
    </div>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="学校名称" prop="schoolName">
        <a-input v-model="form.schoolName" />
      </a-form-model-item>
      <a-form-model-item label="学校负责人姓名" prop="contact">
        <a-input v-model="form.contact" />
      </a-form-model-item>
      <a-form-model-item label="联系电话" prop="phone">
        <a-input v-model="form.phone" />
      </a-form-model-item>
      <a-form-model-item>
        <a-button block type="primary" @click="onSubmit">
          提交
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-layout-content>
</template>

<script>
import {addApplication} from "@/api/school";

export default {
  name: "SchoolSignUp",
  data(){
    return {
      form:{
        schoolName: '',
        contact: '',
        phone: ''
      },
      rules: {
        schoolName: [
          { required: true, message: '请输入学校名称', trigger: 'blur' },
          { max: 30, message: '最大长度为30', trigger: 'blur' },
        ],
        contact: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { max: 10, message: '最大长度为10', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { max: 15, message: '最大长度为15', trigger: 'blur' },
        ],
      },
    }
  },
  created() {
  },
  methods:{
    onSubmit(){
      this.$refs.form.validate(valid => {
        if (valid) {
          addApplication(this.form).then((res) => {
            if(res.code == 200){
              this.$router.push({name:'Success'})
            }
          });
        } else {
          this.$message.warn("请完善信息")
          return false;
        }
      });

    }
  }
}
</script>
ß
<style lang="scss" scoped>
  .header{
    background: #3A90EF;
    padding: 1rem;
    text-align: center;
    .logo{
      width: 96px;
      height: 96px;
    }
  }

  .ant-form{
    padding: 1rem;
  }
  .ant-form-item{
    margin-bottom: 10px;
  }
</style>
