import request from '@/utils/request'

/**
 * 发送短信验证码
 * @param phone
 * @returns {AxiosPromise}
 */
export function sendVerifyCode(phone) {
  return request({
    url: '/sms?phone='+phone,
    method: 'get'
  })
}
