import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'ant-design-vue/dist/antd.css';
import {FormModel,Input,Layout,message,Empty,Button,Avatar,Result,Alert} from 'ant-design-vue';
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false
Vue.use(Layout)
Vue.use(Alert)
Vue.use(Empty)
Vue.use(Input)
Vue.use(Result)
Vue.use(Button)
Vue.use(Avatar)
Vue.use(FormModel)
Vue.use(VueCookies)
Vue.prototype.$message = message;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
