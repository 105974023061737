<template>
  <div class="home">
    <div class="header">
      <img class="logo" src="@/assets/logo_shadow.png" alt="">
      <h3>欢迎使用勤工助学平台</h3>
      <a href="https://qgzx-ny2000.oss-cn-beijing.aliyuncs.com/apk/app-release.apk">
        <a-button type="primary" class="btn-download" icon="android" size="large">
          立即下载
        </a-button>
      </a>

    </div>
    <swiper ref="mySwiper" :options="swiperOptions" :auto-update="true" :auto-destroy="true">
      <swiper-slide>
        <img src="@/assets/images/screen_1.png"/>
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/images/screen_2.png"/>
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/images/screen_3.png"/>
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/images/screen_4.png"/>
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/images/screen_5.png"/>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev"></div><!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
      <div class="swiper-button-next" slot="button-prev"></div><!--右箭头。如果放置在swiper-container外面，需要自定义样式。-->
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <div class="decoration">
      <img class="shape-1" src="@/assets/images/shape-1.svg" alt="">
      <img class="shape-56" src="@/assets/images/shape-56.svg" alt="">
      <img class="shape-57" src="@/assets/images/shape-57.svg" alt="">
      <img class="shape-59" src="@/assets/images/shape-59.svg" alt="">
      <img class="shape-60" src="@/assets/images/shape-60.svg" alt="">
      <img class="shape-2" src="@/assets/images/triangle_1.png" alt="">
      <img class="shape-3" src="@/assets/images/install-arrow-top-light.png" alt="">
    </div>

    <div style="text-align: center;margin-bottom: 1rem">
      <a href="https://beian.miit.gov.cn/" >闽ICP备20015916号-1</a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'
// If you use Swiper 6.0.0 or higher
import 'swiper/swiper-bundle.css'

export default {
  name: 'Home',
  data() {
    let clientWidth = document.body.clientWidth;
    let slidesPerView = 1.1;
    if (clientWidth > 740) {
      slidesPerView = 3;
    }
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        slidesPerView: slidesPerView,
        slideToClickedSlide: true,
        centeredSlides: true,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          hideOnClick: true
        },
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  created() {

  },
  methods: {},
  mounted() {
    this.swiper.slideTo(3, 1000, false)
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/theme.scss';

html, body {
  height: 100%;
}

.home {
  height: 100vh;

  .header {
    background: $color-primary;
    text-align: center;
    width: 100%;
    padding: 1rem;

    .btn-download {
      margin-top: 1rem;
      background-color: orange;
      border-color: rgba(255, 255, 255, 0.5);
      text-align: center;
      color: white;
      border-radius: 5px;
      -webkit-box-shadow: 0px 10px 13px -7px grey, -22px -6px 14px -10px rgba(0, 0, 0, 0);
      box-shadow: 0px 10px 13px -7px grey, -22px -6px 14px -10px rgba(0, 0, 0, 0);
    }
  }

  .logo {
    margin-top: 1rem;
    width: 96px;
    height: 96px;
  }

  .swiper-slide {
    img {
      width: 100%;
    }
  }

  h2, h3 {
    color: white;
  }

  .swiper-container {
  }
}

.decoration {

  img {
    position: fixed;
  }

  .shape-1 {
    left: 10px;
    bottom: 30vh;
  }

  .shape-2 {
    right: 10px;
    bottom: 10px;
    display: none;
  }

  .shape-56 {
    left: 20px;
    top: 50vh;
  }

  .shape-57 {
    left: 70vw;
    top: 60vh;
  }

  .shape-59 {
    left: 60vw;
    bottom: 20px;
  }

  .shape-60 {
    right: 10vw;
    bottom: 20px;
  }

  .shape-3 {
    left: 20px;
    bottom: 20px;
  }


}


.swiper-container {
  //--swiper-theme-color: #ff6600;/* 设置Swiper风格 */
  //--swiper-navigation-color: #00ff33;/* 单独设置按钮颜色 */
  //--swiper-navigation-size: 30px;/* 设置按钮大小 */
}
</style>
