import request from '@/utils/request'

/**
 * 获取字典信息
 * @param dict
 * @returns {AxiosPromise}
 */
export function getSystemDict(dict) {
    return new Promise((resolve, reject) => {
        let cacheData = localStorage.getItem(dict)
        if (cacheData != null && cacheData != undefined && cacheData != 'null') {
            resolve(JSON.parse(cacheData))
        } else {
            request({
                url: '/system/dicts/cache/' + dict,
                method: 'get'
            }).then(res => {
                localStorage.setItem(dict,JSON.stringify(res));
                resolve(res)
            }).catch(res => {
                reject(res)
            })
        }
    })
}

/**
 * 将字典数字转化为标签，例如： 100001->电子商务
 * @param dicts
 * @param value
 * @returns {*}
 */
export function dictValue2Label(dicts, value) {
    for (const dictsKey in dicts) {
        let dict = dicts[dictsKey];
        if (dict.dictValue == value) {
            return dict.dictLabel
        }
    }
}

export let dict = {
    PRACTICE_TYPE: "practice_type",
    POST_TYPE: "post_type",
    PAY_CYCLE: 'pay_cycle',
    QUALIFICATION_REQUIRED: 'qualification_required',
    PAY_UNIT: 'pay_unit',
    /**
     * 岗位评估方式
     */
    POST_EVALUATION_METHODS: 'post_evaluation_methods',
    /**
     * 专业
     */
    MAJOR: 'major',
    /**
     * 企业行业
     */
    ENTERPRISE_INDUSTRY: 'enterprise_industry',
    /**
     * 企业规模
     */
    ENTERPRISE_PEOPLE_NUMBER: 'enterprise_people_number',
}
