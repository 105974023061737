import request from '@/utils/request'

/**
 * 获取公司信息
 * @param id
 * @returns {AxiosPromise}
 */
export function getEnterpriseInfo(id) {
  return request({
    url: '/enterprise/infos/'+id,
    method: 'get',
  })
}
