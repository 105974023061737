<template>
  <a-layout-content style="text-align: left;padding: 1rem">
    <p class="ql-align-center"><b>勤工助学岗位信息发布规则</b></p>
    <p>&nbsp;</p>
    <p>
      感谢您使用勤工助学！本《勤工助学岗位信息发布规则》（以下简称“本规则”）系由您与厦门钟信教育科技有限公司（以下简称“勤工助学”）订立的《勤工助学用户协议》的有效组成部分，与《勤工助学用户协议》共同构成您与勤工助学合作的法律文件。您在注册、使用勤工助学软件发布岗位信息之前，请务必审慎阅读、充分理解本规则。您使用勤工助学软件岗位信息服务的行为，即表示您已阅读并同意本规则所涉全部内容。</p>
    <p>勤工助学有权根据法律法规、政策及产品需求更新本规则，并通过软件发布更新版本的方式修改本规则，修改后的规则于发布时生效。请您定期访问并查看最新规则。如您继续使用勤工助学服务的，即表示您接受经修订后的规则。</p>
    <p>1.勤工助学为用户提供发布岗位招聘信息的免费服务和增值服务。用户使用勤工助学软件应当遵守本规则以及其他与勤工助学签署的协议。</p>
    <p>2.勤工助学有权对不同的岗位类别下岗位信息设置不同的有效期，有效期届满时该岗位信息可能被下线。</p>
    <p>3.勤工助学有权对用户发布的岗位信息进行审核，对于不符合本规则的内容，有权拒绝发布或者删除。</p>
    <p>4.用户不得发布包含下列内容的岗位信息或其他信息：</p>
    <p>(1)包含广告（寻求合作）、传销、直销等内容</p>
    <p>(2)包含色情、淫秽内容</p>
    <p>(3)包含违法、政治敏感内容</p>
    <p>(4)包含不真实的公司信息</p>
    <p>(5)包含虚假的企业身份信息</p>
    <p>(6)包含不真实的薪资、工作地点，或者工作地点不在中国境内的</p>
    <p>(7)包含不真实的岗位描述、工作内容</p>
    <p>(8)岗位类型与岗位描述不符的</p>
    <p>(9)一个岗位发布多个岗位</p>
    <p>(10)岗位名称与岗位描述不明确的</p>
    <p>(11)岗位名称包含岗位名称以外信息的</p>
    <p>(12)涉及违反《劳动合同法》及相关法律法规的要求</p>
    <p>(13)包含性别歧视或指向性歧视，如地域歧视的内容</p>
    <p>(14)包含人身攻击或其他侵害他人权益的内容</p>
    <p>(15)兼职模特、试衣模特类岗位</p>
    <p>(16)无经验交易员、分析师类岗位</p>
    <p>(17)涉及网约车司机套路贷类岗位</p>
    <p>(18)非正常招聘助理类岗位</p>
    <p>(19)跟剧组类岗位</p>
    <p>(20)演员、练习生、歌手类岗位</p>
    <p>(21)夜场等娱乐会所类岗位</p>
    <p>(22)船员、普工类岗位且身份存疑</p>
    <p>(23)信用卡套现类岗位</p>
    <p>(24)淘宝刷单、微商代理、手机兼职、手工兼职类岗位</p>
    <p>(25)多个相同岗位</p>
    <p>(26)游戏代练类岗位&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
    <p>(27)被多位用户举报</p>
    <p>(28)涉及发布代孕、捐精、捐卵业务</p>
    <p>(29)发布大量（【5】个以上）兼职主播类岗位，可能存在恶意的</p>
    <p>(30)挂靠类岗位</p>
    <p>(31)发布的岗位信息与实际所招聘岗位不符&nbsp;</p>
    <p>(32)部分涉及保险业务的岗位（除部分内测用户以外）</p>
    <p>(33)发布涉及兼职推广或为其他平台拉用户等行为的岗位</p>
    <p>(34)其他（鸡汤、段子、水贴等）</p>
    <p>用户发布的岗位信息或其他信息包含但不限于上述内容的，经勤工助学发现或用户投诉后核实的，勤工助学有权驳回其岗位发布申请、删除岗位信息。</p>
    <p>5.勤工助学不为下述公司提供岗位发布服务，因此如使用勤工助学服务的用户服务于下述公司的，勤工助学有权删除其发布的岗位信息：</p>
    <p>(1)存在猎头业务的公司（经认证的“人才经纪人”除外）</p>
    <p>(2)存在招聘流程外包等人力资源服务的公司（经认证的“人才经纪人”除外）</p>
    <p>(3)存在招聘业务的公司</p>
    <p>(4)所招聘岗位无薪资或存在涉及拒发或恶意拖欠工资的行为</p>
    <p>(5)涉及以招聘名义招人培训的公司</p>
    <p>(6)存在淘宝刷单、微商代理、手机兼职、手工兼职业务的公司</p>
    <p>(7)存在跟剧组行为的公司</p>
    <p>(8)存在兼职模特、试衣模特业务的公司</p>
    <p>(9)存在培养演员、练习生、歌手业务的公司</p>
    <p>(10)存在需要求职者去境外工作的公司</p>
    <p>(11)夜场等娱乐场所的公司</p>
    <p>(12)涉及收取求职者费用的公司</p>
    <p>(13)存在直销或传销模式业务的公司&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
    <p>(14)存在信用卡套现的公司</p>
    <p>(15)存在挂靠业务的公司</p>
    <p>(16)存在网约车司机涉及套路贷业务的公司</p>
    <p>(17)在国家企业信用信息公示系统中被列入经营异常名录的公司</p>
    <p>(18)在国家企业信用信息公示系统中的登记状态为注销/吊销状态的公司</p>
    <p>(19)已被法院判定为破产或正在进行破产申请流程的公司</p>
    <p>(20)在第三方网站中查询到存在严重失信行为的公司</p>
    <p>(21)在国家企业信用信息公示系统中查询不到注册信息的公司</p>
    <p>(22)被多位用户举报的公司</p>
    <p>(23)涉及发布代孕、捐精、捐卵的业务&nbsp;&nbsp;&nbsp;&nbsp;</p>
    <p>(24)存在恶意发布大量兼职主播岗位的公司</p>
    <p>(25)涉及兼职推广或为其他平台拉用户等业务的公司</p>
    <p>(26)部分保险公司（除部分内测用户以外）</p>
    <p>6.勤工助学严禁用户利用勤工助学服务，实施下述行为：</p>
    <p>(1)以培训费、服装费、工作需要等任何名义骗取求职者财物或涉及收取求职者费用、要求求职者贷款</p>
    <p>(2)实施传销活动或骗取他人加入传销组织</p>
    <p>(3)招聘他人从事违法活动</p>
    <p>(4)向求职者或其他用户索取个人信息、隐私内容</p>
    <p>(5)骚扰其他用户</p>
    <p>(6)存在辱党、辱国、传播邪教思想的行为</p>
    <p>(7)存在使用涉及各国政治人物的图片</p>
    <p>(8)存在使用含色情、淫秽内容的图片</p>
    <p>(9)存在使用人身攻击或其他侵害他人权益的图片</p>
    <p>(10)存在使用广告（寻求合作）的头像</p>
    <p>(11)存在帮其他公司招聘或猎头、RPO的行为（经认证的“人才经纪人”除外）</p>
    <p>(12)以招聘名义招人培训</p>
    <p>(13)所招聘岗位无薪资或存在涉及拒发或恶意拖欠工资的行为</p>
    <p>(14)招聘未满16周岁人员</p>
    <p>(15)其他关联账号存在违规行为&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
    <p>(16)其他违反法律规定的行为&nbsp;</p>
    <p>用户实施上述行为，经勤工助学发现或用户投诉后核实的，勤工助学有权立即停止对该用户提供服务、删除用户发布的岗位信息。</p>
    <p>
      7.若用户违反前述第4条、第5条、第6条规定的内容，或者有其他明显损害勤工助学利益或违法行为的，勤工助学有权视情节采取冻结用户账户的方式暂时乃至永久停止其使用勤工助学服务且不做解封与退款处理。如用户行为涉嫌违法犯罪的，勤工助学有权向有关行政机关及司法部门举报、披露相关信息。</p>
    <p>8.用户违反本规则的，勤工助学可以依据本规则直接采取相关措施，并视情节严重程度选择是否通知用户。接到通知的用户可以通过客户服务渠道向勤工助学提交申诉。</p>
  </a-layout-content>
</template>

<script>
export default {
  name: 'PostRules',
  created() {
    document.title = '勤工助学岗位信息发布规则'
  }
}
</script>
<style lang="scss">
.ql-align-center{
  text-align: center;
}
</style>
