<template>
  <a-layout-content class="content">
    <div class="header">
      <img src="../assets/logo_shadow.png" class="logo"/>
      <h3 style="color:white">{{ name }}邀请您成为公司导师</h3>
    </div>

    <a-alert
        style="margin: 16px"
        v-if="userType == '1'"
        message="当前手机已注册为学生身份，若注册为导师后，将无法再转为学生用户"
        type="warning"
        show-icon
    />

    <a-form-model ref="form" :model="form" :rules="rules" :validateMessages="{required: '必填项'}">
      <a-form-model-item label="姓名" prop="name" required>
        <a-input v-model="form.name"/>
      </a-form-model-item>
      <a-form-model-item label="手机号" prop="phone" required>
        <a-input-search v-model="form.phone" @blur="checkUserType()" @search="sendCode()">
          <a-button :disabled="countdown > 0" :loading="sending" slot="enterButton">
            {{ countdown > 0 ? countdown + 's' : '发送验证码' }}
          </a-button>
        </a-input-search>
      </a-form-model-item>
      <a-form-model-item label="验证码" prop="verifyCode" required>
        <a-input v-model="form.verifyCode"/>
      </a-form-model-item>
      <a-form-model-item label="职称" prop="dutyName">
        <a-input v-model="form.dutyName"/>
      </a-form-model-item>
      <a-form-model-item label="简介" prop="introduction">
        <a-input v-model="form.introduction" type="textarea"/>
      </a-form-model-item>

      <a-form-model-item label="登录密码" prop="password" has-feedback required>
        <a-input v-model="form.password" autocomplete="off" type="password"/>
      </a-form-model-item>

      <a-form-model-item label="确认密码" prop="passwordConfirm" has-feedback required>
        <a-input v-model="form.passwordConfirm" autocomplete="off" type="password"/>
      </a-form-model-item>
      <a-form-model-item>
        <a-button block type="primary" @click="onSubmit" :disabled="sending">
          确 定
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-layout-content>
</template>

<script>
import {getEnterpriseNameByCode, joinEnterprise, getUserTypeByPhone} from '@/api/tutors'
import {sendVerifyCode} from '@/api/sms'

export default {
  name: "TutorsSignUp",
  data() {
    let validatePass = (rule, value, callback) => {
      console.log(value)
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (value !== '') {
          this.$refs.form.validateField('passwordConfirm');
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请确认密码'));
      } else if (value !== this.form.password) {
        callback(new Error("两次密码不一致"));
      } else {
        callback();
      }
    };
    return {
      form: {
        name: "",
        phone: "",
        password: "",
        passwordConfirm: "",
        verifyCode: "",
        dutyName:"",
        introduction:""
      },
      rules: {
        required: '%s 必填',
        password: [{validator: validatePass, trigger: 'change'}],
        passwordConfirm: [{validator: validatePass2, trigger: 'change'}],
      },
      sending: false,
      code: "",
      name: "",
      userType: "",
      countdown: 0,
    }
  },
  created() {
    this.code = this.$route.params.code;
    getEnterpriseNameByCode(this.code).then((res) => {
      this.name = res.data;
    })
  },
  watch: {
    countdown: function (newValue) {
      if (newValue > 0) {
        setTimeout(this.updateCountdown, 1000)
      }
    }
  },
  methods: {
    updateCountdown() {
      this.countdown = this.countdown - 1
    },
    checkUserType() {
      let phone = this.form.phone;
      if (phone.length < 10) {
        return
      }
      getUserTypeByPhone(phone).then((res) => {
        this.userType = res.data
        console.log("userType：" + this.userType)
      })
    },
    onSubmit() {
      console.log("onSubmit")
      console.log(this.$refs.form)
      this.$refs.form.validate(valid => {
        console.log(valid)
        if (valid) {
          this.sending = true
          this.form.userType = this.userType;
          joinEnterprise(this.code, this.form).then(() => {
            this.$router.push({name: "Success"})
          }).finally(() => {
            this.sending = false;
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    sendCode() {
      if (this.countdown > 0) {
        return
      }
      this.sending = true
      sendVerifyCode(this.form.phone).then(() => {
        this.$message.success("发送成功")
        this.countdown = 60;
      }).finally(() => {
        this.sending = false
      });
    }

  }
}
</script>

<style lang="scss" scoped>
.header {
  background: #3A90EF;
  padding: 1rem;
  text-align: center;

  .logo {
    width: 96px;
    height: 96px;
  }
}

.ant-form {
  padding: 1rem;
}

.ant-form-item {
  margin-bottom: 10px;
}
</style>
