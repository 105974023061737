import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Privacy from '../views/Privacy'
import Contact from '../views/Contact'
import PostRules from '../views/PostRules.vue'
import Post from '../views/Post'
import Enterprise from '../views/Enterprise'
import TutorsSignUp from "@/views/TutorsSignUp";
import SchoolSignUp from "@/views/SchoolSignUp";
import Success from "@/views/Success";
import AntiFraud from "@/views/AntiFraud";
import Deposit from "@/views/Deposit";
import ServiceRate from "@/views/ServiceRate";
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/service_rate',
        name: 'ServiceRate',
        component: ServiceRate
    },
    {
        path: '/deposit',
        name: 'Deposit',
        component: Deposit
    },
    {
        path: '/antifraud',
        name: 'AntiFraud',
        component: AntiFraud
    },
    {
        path: '/success',
        name: 'Success',
        title: '提示',
        component: Success
    },
    {
        path: '/school',
        name: 'SchoolSignUp',
        component: SchoolSignUp
    },
    {
        path: '/postrules',
        name: 'PostRules',
        component: PostRules
    },
    {
        path: '/post/:id',
        name: 'Post',
        component: Post
    },
    {
        path: '/tutors/:code',
        name: 'TutorsSignUp',
        component: TutorsSignUp
    },
    {
        path: '/enterprise/:id',
        name: 'Enterprise',
        component: Enterprise
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
