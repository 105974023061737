import request from '@/utils/request'


/**
 * 获取岗位信息
 * @param id
 * @returns {AxiosPromise}
 */
export function getPostInfo(id) {
    return request({
        url: '/posts/' + id,
        method: 'get'
    })
}


/**
 * 获取岗位副本信息
 * @param id
 * @returns {AxiosPromise}
 */
export function getDuplicateInfo(id) {
    return request({
        url: '/students/posts/' + id,
        method: 'get'
    })
}
