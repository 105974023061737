<template>
  <a-layout-content>
    <template v-if="!enterprise">
      <a-empty style="margin-top: 3rem">
        <span slot="description">企业不存在，或已被禁用</span>
      </a-empty>
    </template>
    <template v-else>
      <a-avatar :size="60" :src="enterprise.logo" class="logo"></a-avatar>
      <div class="title">{{ enterprise.fullName }}</div>
      <div class="subtitle"><span>{{ enterprise.peopleNumber }}</span> · <span>  {{ enterprise.industry }}</span></div>

      <span class="divider"/>

      <div class="section">
        <div class="title">企业简介</div>
        <div class="content">
          {{ enterprise.introduction }}
        </div>
      </div>
      <span class="divider"/>

      <div class="section">
        <div class="title">企业地址</div>
        <div class="content">
          {{ enterprise.address }}
          <img :src="locationUrlImage" alt="" class="location-image">
        </div>
      </div>
      <span class="divider"/>

      <div class="section">
        <div class="title">企业环境</div>
        <div class="images">
          <img style="with:100%" alt="" v-for="(item,index) in enterprise.environments" :src="item" :key="item+index">
        </div>
      </div>
      <span class="divider"/>

      <div class="bottom">
        <router-link to="/">
          <a-button type="primary" icon="mobile" class="btn-primary" style="margin: 1rem">
            在APP中查看
          </a-button>
        </router-link>
      </div>
    </template>

  </a-layout-content>

</template>
<script>
import {getEnterpriseInfo} from '@/api/enterprise';
import {getSystemDict, dict, dictValue2Label} from '@/api/sysDict';

export default {
  data() {
    return {
      enterprise: {},
      locationUrlImage: ""
    }
  },
  created() {
    let id = this.$route.params.id;
    getEnterpriseInfo(id).then(res => {
      console.log(res)
      this.enterprise = res.data
      getSystemDict(dict.ENTERPRISE_INDUSTRY).then((res) => {
        this.enterprise.industry = dictValue2Label(res.data, this.enterprise.industryCode);
      });
      getSystemDict(dict.ENTERPRISE_PEOPLE_NUMBER).then((res) => {
        this.enterprise.peopleNumber = dictValue2Label(res.data, this.enterprise.peopleNumber);
      });

      let locationStr = this.enterprise.lon + ',' + this.enterprise.lat;
      this.locationUrlImage = "https://restapi.amap.com/v3/staticmap?location=" + locationStr
          + "&zoom=16&size=700*280&markers=-1,https://qgzx-ny2000.oss-cn-beijing.aliyuncs.com/location_mid.png,0:" + locationStr
          + "&key=22752a6d99c2ed45ec3cf5060e8695e0"
    })
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
@import "../assets/theme";

body {
  font-family: PingFangSC-Semibold, PingFang SC, serif;
  text-align: left;
}

.title {
  font-size: 18px;
  font-weight: 600;
  margin-top: 14px;
  margin-bottom: 8px;
}

.subtitle {
  font-size: 16px;
  font-weight: 400;
  color: #979797;
}

.logo {
  margin-top: 2rem;
}

.pay {
  font-size: 24px;
  font-weight: 600;
  color: rgba(231, 117, 71, 1);

  .unit {
    font-size: 12px;
  }

  .cycle {
    font-size: 12px;
    font-weight: 400;
    margin-left: 1rem;
    color: rgba(153, 153, 153, 1);
  }
}

.header {
  padding: 16px 16px 0 16px;
  text-align: left;
}

.section {
  padding: 8px 16px;
  text-align: left;

  .title {
    font-size: 18px;
    font-weight: 600;
    color: $text-color;
    margin-bottom: 8px;
  }

  .tags {
    display: flex;
    width: 100%;

    .tag {
      text-align: center;
      font-weight: 400;
      color: $color-primary;
      padding: 1px 5px;
      min-width: 64px;
      border-radius: 3px;
      margin: 0.5rem 0.5rem 0.5rem 0;
      border: 1px solid $color-primary;
    }
  }

  table {
    .key {
      font-weight: 400;
      color: #979797;
      vertical-align: top;
      min-width: 75px;
    }

    .value {
      font-size: 14px;
      font-weight: 500;
      color: $text-color;
    }
  }

  .content {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
  }

  .enterprise {
    display: flex;
    align-items: center;

    .name {
      margin-left: 16px;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .location-image {
    width: 100%;
    margin-top: 0.5rem;
  }

  .images {
    display: flex;
    overflow-x: auto;

    img {
      width: 110px;
      margin-right: 12px;
      height: 90px;
      border-radius: 8px;
    }
  }
}

.divider {
  display: block;
  height: 10px;
  width: 100%;
  background: $bg-color;
  margin-top: 14px;
}

.btn-primary {
  width: 206px;
  height: 45px;
  background: #3A90EF;

}

.ant-layout-content {
  background: white;
  color: $text-color;

  .bottom {
    display: flex;
    justify-content: center;
  }
}
</style>
