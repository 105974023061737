<template>
  <div class="content">
    <h2 class="title">防骗指南</h2>
    <h3>刷单做任务返费</h3>
    <p>非法商家假借知名购物网站（淘宝/京东/网易考拉等），以其为诱饵提供购物链接给求职者，并利用私人 微信或支付宝收款二维码要求求职者下单后把钱转账给 私人账户后失联。</p>
    <h3>网络兼职</h3>
    <p>非法商家利用YY、IS语音等网络工具骗取培训费/马甲费/押金等；微商借用服务费、代理费引导求职者交费 的存在诈骗风险。</p>
    <h3>做任务盗取信息</h3>
    <p>非法商家QQ/微信引导购买游戏点卡/手机话费卡等，以做任务或返费等名义要求提供卡号以及密码，盗取卡内金额后失联。</p>
    <h3>非法中介</h3>
    <p>非法中介，以介绍工作名义收取报名费、保证金、押金、信息服务费等，但不提供工作，或提供工作与承诺的不符。</p>
  </div>
</template>

<script>
export default {
  name: "AntiFraud"
}
</script>

<style lang="scss" scoped>
  .content{
    padding: 1rem;
    text-align: left;
    .title{
      text-align: center;
    }
    p{
      color: gray;
    }
  }
</style>
