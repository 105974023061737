import axios from 'axios'
import errorCode from '@/utils/errorCode'
import {message} from "ant-design-vue";
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: 'https://worklearn.net/api/',
  // baseURL: 'http://localhost:8080/api/',
  // 超时
  timeout: 10000
})

service.interceptors.response.use(res=>{
  const code = res.data.code || 200;
  const msg = errorCode[code] || res.data.msg || errorCode['default']
  if (code === 500) {
    message.error(msg,5)
    return Promise.reject(new Error(msg))
  } else if (code !== 200) {
    message.error(msg,5)
    return Promise.reject('error')
  } else {
    return res.data
  }
})


export default service
