<template>
  <div class="content">
    <h2 class="title">押金说明</h2>
    <p>为保证学生权利，凡在勤工助学平台发布岗位的企业，都需要缴纳一定数额押金。押金仅当学生与企业发生纠纷时会被冻结，不会用于其他用途。</p>
    <h3>押金提现条件</h3>
    <ul>
      <li>没有未完成或正在招聘的岗位；</li>
      <li>没有进行中的维权、投诉；</li>
      <li>没有待付款的账单；</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Deposit",
  created() {
  }
}
</script>

<style scoped lang="scss">
.content {
  padding: 1rem;
  text-align: left;

  .title {
    text-align: center;
  }

  p {
    color: gray;
  }
}
</style>
