<template>
  <div class="content">
    <h2 class="title">平台服务费说明</h2>
    <ul>
      <li>当前平台费率为<span class="rate">{{rate}}%</span>。</li>
      <li>费率计算方式为：<span class="rate">日薪*费率</span> 。若发布岗位的薪酬为月薪，则按 <span class="rate">月薪/30*费率</span> 计算。</li>
      <li>服务费会在岗位完成后（包括中途离职）产生。</li>
    </ul>
  </div>
</template>

<script>
import {getConfigKey} from '@/api/sysConfig'
export default {
  name: "Deposit",
  data(){
    return{
      rate: 0,
      serviceChargeCap:'',
    }
  },
  created() {
    getConfigKey('sys.platform.serviceRate').then((res)=>{
      this.rate = parseInt(res.data) * 0.1;
    })
  }
}
</script>

<style scoped lang="scss">
@import "../assets/theme";

.content {
  padding: 1rem;
  text-align: left;

  .title {
    text-align: center;
  }

  p {
    color: gray;
  }
  .rate{
    font-weight: bold;
    color: $color-primary;
  }
}
</style>
