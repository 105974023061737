import request from '@/utils/request'

/**
 * 获取根据您邀请码公司信息
 * @param code
 * @returns {AxiosPromise}
 */
export function getEnterpriseNameByCode(code) {
  return request({
    url: '/enterprise/tutors/info/'+code,
    method: 'get',
  })
}

export function joinEnterprise(code,data){
  return request({
    url: '/enterprise/tutors/join/'+code,
    method: 'post',
    data: data
  })
}

export function getUserTypeByPhone(phone){
  return request({
    url:'/system/users/type/'+phone,
    method:'get'
  })
}


