<template>
  <a-layout-content style="text-align: left;padding: 1rem">
    <p>
      如果您在使用我们的产品时遇到任何问题，请随时与我们联系，我们将全力全意为您解决！<br>
      请发邮件与我们联系，我们将24小时为您服务！<br>
      email：<a href="mailto:170241061@qq.com">170241061@qq.com</a> <br>
      Tel: <a href="tel:15359279791">15359279791</a> <br>
      <br>
      谢谢!
    </p>
  </a-layout-content>
</template>

<script>

export default {
  name: 'Contact',
  created() {
    document.title = '技术支持'
  }
}
</script>
<style lang="scss">
.ql-align-center {
  text-align: center
}
</style>
