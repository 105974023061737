<template>
  <a-layout-content>
    <template v-if="!post || post.delFlag !== '0'">
      <a-empty style="margin-top: 3rem">
        <span slot="description">岗位不存在，或已被删除</span>
      </a-empty>
    </template>
    <template v-else>
      <div class="header">
        <div class="title">{{ post.postTitle }}</div>
        <div class="pay">{{ post.pay }}<span class="unit">/{{ post.payUnit }}</span>
          <span class="cycle">{{ post.payCycle }}</span></div>
      </div>
      <div class="section">
        <div class="title">招聘要求</div>
        <div class="tags">
          <span class="tag">{{ post.qualification }}</span>
          <span class="tag">{{ post.type }}</span>
          <span class="tag">招{{ post.recruitNumber }}人</span>
        </div>
      </div>
      <span class="divider"/>

      <div class="section">
        <div class="title">岗位任务</div>
        <div class="content">{{ post.content }}</div>
      </div>

      <span class="divider"/>

      <div class="section">
        <div class="title">课程信息</div>
        <table>
          <tr>
            <td class="key">学习内容：</td>
            <td class="value">{{ post.trainingTarget }}</td>
          </tr>
          <tr>
            <td class="key">培养目标：</td>
            <td class="value">{{ post.knowledge }}</td>
          </tr>
          <tr>
            <td class="key">考核方式：</td>
            <td class="value">{{ post.evaluationMethod }}</td>
          </tr>
          <tr>
            <td class="key">适合专业：</td>
            <td class="value"> {{ post.majors }}</td>
          </tr>
        </table>
      </div>

      <span class="divider"/>

      <div class="section">
        <div class="title">工作地点</div>
        <div class="content">{{ post.address }}
          <img :src="locationUrlImage" class="location-image">
        </div>
      </div>
      <span class="divider"/>

      <div class="section">
        <div class="title">工作时间</div>
        <table>
          <tr>
            <td class="key">开始时间：</td>
            <td class="value">{{ post.beginTime }}</td>
          </tr>
          <tr>
            <td class="key">结束时间：</td>
            <td class="value">{{ post.endTime }}</td>
          </tr>
        </table>
      </div>
      <span class="divider"/>

      <div class="section">
        <div class="title">企业信息</div>
        <router-link :to="{name:'Enterprise', params: { id: 1 }}">
          <div class="enterprise">
            <a-avatar :size="50" :src="post.enterpriseLogo"/>
            <span class="name">{{ post.enterpriseName }}</span>
          </div>
        </router-link>
      </div>

      <div class="bottom">
        <router-link to="/">
          <a-button type="primary" icon="mobile" class="btn-primary" style="margin: 1rem">
            在APP中查看
          </a-button>
        </router-link>

      </div>
    </template>

  </a-layout-content>

</template>
<script>
import {getPostInfo, getDuplicateInfo} from '@/api/post';
import {getSystemDict, dict} from '@/api/sysDict';
import moment from 'moment';
import array from 'lodash';

export default {
  data() {
    return {
      post: {},
      locationUrlImage: "",
    }
  },
  created() {
    let id = this.$route.params.id;
    let isSource = this.$route.query.source;
    if (isSource == 0) {
      getDuplicateInfo(id).then((res) => {
        this.post = res.data;
        if (!this.post) {
          return
        }
        this.updatePostData()
      });
    } else {
      getPostInfo(id).then((res) => {
        this.post = res.data;
        if (!this.post) {
          return
        }
        this.updatePostData()
      });
    }

  },
  methods: {
    updatePostData() {
      getSystemDict(dict.POST_TYPE).then((res) => {
        this.post.type = this.dictValue2Label(res.data, this.post.type);
      });
      getSystemDict(dict.PAY_CYCLE).then((res) => {
        this.post.payCycle = this.dictValue2Label(res.data, this.post.payCycle);
      });
      getSystemDict(dict.QUALIFICATION_REQUIRED).then((res) => {
        this.post.qualification = this.dictValue2Label(res.data, this.post.qualification);
      });
      getSystemDict(dict.PAY_UNIT).then((res) => {
        this.post.payUnit = this.dictValue2Label(res.data, this.post.payUnit);
      });
      getSystemDict(dict.POST_EVALUATION_METHODS).then((res) => {
        this.post.evaluationMethod = this.dictValue2Label(res.data, this.post.evaluationMethod);
      });
      getSystemDict(dict.MAJOR).then((res) => {
        let majorValues = this.post.majors.split(',');
        let majors = res.data.filter(it => {
          return majorValues.includes(it.dictValue)
        })
        //过滤大类专业中的子专业
        majors.forEach(it => {
          this.removeSubMajor(majors, it.dictValue)
        })

        this.post.majors = majors.map(it => it.dictValue).map(it => {
          return this.dictValue2Label(majors, it);
        }).join('，');
      });

      this.post.beginTime = moment(this.post.beginTime).format('YYYY-MM-DD')
      this.post.endTime = moment(this.post.endTime).format('YYYY-MM-DD')

      let locationStr = this.post.lon + ',' + this.post.lat;
      this.locationUrlImage = "https://restapi.amap.com/v3/staticmap?location=" + locationStr
          + "&zoom=16&size=700*280&markers=-1,https://qgzx-ny2000.oss-cn-beijing.aliyuncs.com/location_mid.png,0:" + locationStr
          + "&key=22752a6d99c2ed45ec3cf5060e8695e0"
    },
    dictValue2Label(dicts, value) {
      for (const dictsKey in dicts) {
        let dict = dicts[dictsKey];
        if (dict.dictValue == value) {
          return dict.dictLabel
        }
      }
    },
    removeSubMajor(majors, majorValue) {
      array.remove(majors, it => {
        let isChild = it.dictParentValue == majorValue
        if (isChild) {
          this.removeSubMajor(majors, it.dictValue)
        }
        return isChild
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/theme";

body {
  font-family: PingFangSC-Semibold, PingFang SC, serif;
  text-align: left;
}

.title {
  width: 290px;
  height: 33px;
  font-size: 24px;
  font-weight: 600;
  color: rgba(51, 51, 51, 1);
  line-height: 33px;
}

.pay {
  font-size: 24px;
  font-weight: 600;
  color: rgba(231, 117, 71, 1);

  .unit {
    font-size: 12px;
  }

  .cycle {
    font-size: 12px;
    font-weight: 400;
    margin-left: 1rem;
    color: rgba(153, 153, 153, 1);
  }
}

.header {
  padding: 16px 16px 0 16px;
  text-align: left;
}

.section {
  padding: 8px 16px;
  text-align: left;

  .location-image {
    width: 100%;
    margin-top: 0.5rem;
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    color: $text-color;
    margin-bottom: 8px;
  }

  .tags {
    display: flex;
    width: 100%;

    .tag {
      text-align: center;
      font-weight: 400;
      color: $color-primary;
      padding: 1px 5px;
      min-width: 64px;
      border-radius: 3px;
      margin: 0.5rem 0.5rem 0.5rem 0;
      border: 1px solid $color-primary;
    }
  }

  table {
    .key {
      font-weight: 400;
      color: #979797;
      vertical-align: top;
      min-width: 75px;
    }

    .value {
      font-size: 14px;
      font-weight: 500;
      color: $text-color;
    }
  }

  .content {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
  }


  .enterprise {
    display: flex;
    align-items: center;

    .name {
      margin-left: 16px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.divider {
  display: block;
  height: 10px;
  width: 100%;
  background: $bg-color;
}

.btn-primary {
  width: 206px;
  height: 45px;
  background: #3A90EF;

}

.ant-layout-content {
  background: white;
  color: $text-color;

  .bottom {
    display: flex;
    justify-content: center;
  }
}
</style>
