<template>
  <a-layout-content style="text-align: left;padding: 1rem">
    <p class="ql-align-center"><strong style="color: rgb(0, 0, 0);">勤工助学《用户协议及隐私政策》</strong></p>
    <p><span style="color: rgb(0, 0, 0);">【更新日期：2020年08月20日】</span></p>
    <p><span style="color: rgb(0, 0, 0);">&nbsp;尊敬的用户：</span></p>
    <p><span style="color: rgb(0, 0, 0);">&nbsp;&nbsp;在您成为勤工助学注册用户，使用勤工助学提供的服务之前，请您认真阅读本《用户协议及隐私政策》，更好的了解我们所提供的服务以及您享有的权利和承担的义务。您一旦开始使用勤工助学服务，即表示您已经确认并接受了本文件中的全部条款。</span>
    </p>
    <p><br></p>
    <p class="ql-align-center"><strong style="color: rgb(0, 0, 0);">第一部分 用户协议</strong></p>
    <p><span style="color: rgb(0, 0, 0);">&nbsp;&nbsp;本用户协议（以下简称“协议”）系由您（以下简称“用户”或“您”）与厦门钟信教育科技有限公司（以下简称“钟信教育公司”或“我们”）（注册地址：厦门市思明区天湖路38号332室，联系电话：0592-5966006）就厦门钟信教育科技有限公司提供的勤工助学软件（以下简称“本平台”或“勤工助学”）所订立的相关权利义务规范。勤工助学软件是一个严肃纯净的勤工助学岗位对接平台服务软件，请您在注册、使用勤工助学之前，认真阅读以下条款：</span>
    </p>
    <p><strong style="color: rgb(0, 0, 0);">一、注册条款的接受</strong></p>
    <p><span style="color: rgb(0, 0, 0);">一旦您在注册页面点击或勾选“阅读并同意接受用户协议及隐私政策”相关内容后，即表示您已经阅读并且同意与厦门钟信教育科技有限公司达成协议，成为勤工助学的用户，并接受本用户协议中的全部注册条款以及第二部分《个人信息保护政策》和勤工助学软件内公布的其他专项协议或规则（包括</span><a
        href="/postrules" rel="noopener noreferrer"
        target="_blank" style="color: rgb(65, 74, 96);">《勤工助学岗位信息发布规则》</a><span
        style="color: rgb(0, 0, 0);">）的所有条款的约束。</span></p>
    <p><strong style="color: rgb(0, 0, 0);">二、用户注册条件</strong></p>
    <p><span
        style="color: rgb(0, 0, 0);">1.申请注册成为勤工助学的用户应同时满足下列全部条件：在注册之日以及此后使用勤工助学服务期间必须以招聘和/或求职为目的；在注册之日必须年满16周岁以上。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">2.为了更好地享有勤工助学网络信息发布平台服务，用户应遵守勤工助学软件注册机制的要求，向勤工助学提供本人真实、正确、最新及完整的资料；如注册为招聘主体的用户，应保证及时更新本人提供的“单位名称、职务或岗位信息、企业邮箱”等相关授权信息及材料，并确保前述授权的真实性；用户应保证其职务行为，包括但不限于发布招聘信息、与求职者沟通等均在使用本平台期间持续有效；通过认证的用户应保持其招聘帐号与对应的授权单位具有唯一性。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">3.若用户提供任何错误、不实或不完整的资料，或勤工助学有理由怀疑资料为错误、不实或不完整及违反用户注册条款的，或勤工助学有理由怀疑其用户资料、言行等有悖于“严肃纯净的招聘APP”主题或违反</span><a
        href="/postrules" rel="noopener noreferrer"
        target="_blank" style="color: rgb(65, 74, 96);">《勤工助学岗位信息发布规则》</a><span style="color: rgb(0, 0, 0);">的，勤工助学有权修改用户的注册昵称、个人说明、发布的信息等，或暂停或终止该用户使用其帐号，或暂停或终止提供勤工助学提供的全部或部分服务。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">4.若用户故意提供虚假的身份信息、公司信息进行注册，发布虚假招聘信息或者求职信息的，视为严重违反本用户协议，勤工助学有权暂停或终止该用户账号并停止提供服务。虚假注册、发布虚假信息给勤工助学造成经济、名誉等任何损失的，勤工助学将追究该用户的法律责任。</span>
    </p>
    <p><strong style="color: rgb(0, 0, 0);">三、用户帐号、密码及安全</strong></p>
    <p><span style="color: rgb(0, 0, 0);">1.在完成用户注册程序之后，用户将收到一个密码及帐号。用户有义务保证密码及帐号的安全。用户应对利用该密码及帐号所进行的一切活动负全部责任，包括任何经由勤工助学上载、张贴、发送电子邮件或任何其它方式传送的资讯、资料、文字、软件、音乐、音讯、照片、图形、视讯、信息或其它资料，无论系公开还是私下传送，均由内容提供者承担责任。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">2.用户的密码或帐号遭到未获授权的使用，或者发生其它任何安全问题时，用户应立即通知勤工助学。由于用户使用不当或者其他非因勤工助学导致的帐号、密码泄漏，进而导致其资料、信息泄漏的，由用户承担其不利后果。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">3.用户不得将账号主动告知第三方或提供给第三方进行使用，例如提供给第三方进行代为购买勤工助学服务等。如因此造成用户隐私泄露或勤工助学平台损失的，用户应当承担相应的责任。</span>
    </p>
    <p><strong style="color: rgb(0, 0, 0);">四、服务说明</strong></p>
    <p><span style="color: rgb(0, 0, 0);">1.勤工助学通过国际互联网为用户提供网络服务，包括在线及离线的相关业务。为使用网络服务，用户应自行配备进入国际互联网所必需的设备，包括计算机、数据机或其它存取装置，并自行支付登陆国际互联网所需要的费用。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">2.勤工助学在提供网络服务时，可能会对部分网络服务收取一定的费用，在此情况下，会在相关页面上做明确的提示。如用户拒绝支付该等费用，则不能使用相关的网络服务。付费业务将在本注册条款的基础上另行规定服务条款，以规范付费业务的内容和双方的权利义务，用户应认真阅读，如用户购买付费业务，则视为接受付费业务的服务条款。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">3.无论是付费服务还是勤工助学的免费服务均有有效期，有效期结束后服务将自动终止，且有效期不可中断或延期。除非本注册条款或其他相关服务条款另有规定，所有付费业务均不退费。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">4.对于利用勤工助学进行非法活动，或其言行（无论线上或者线下的）背离勤工助学严肃招聘目的的，勤工助学将严肃处理，包括将其列入黑名单、将其被投诉的情形公之于众、删除用户帐号等处罚措施，给勤工助学造成经济或者名誉等任何损失的，勤工助学将追究其法律责任。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">5.勤工助学有权通过拨打电话、发送短信或电子邮件等方式，告知用户勤工助学服务相关的广告信息、促销优惠等营销信息，以及邀请用户参与版本测试、用户体验反馈、回访等活动。除系统通知或重要信息外，用户可以通过勤工助学提供的方式选择不接收上述信息。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">6.勤工助学的虚拟道具等增值服务只在产品平台上或由勤工助学直接对外出售或者赠送使用权，用户不得在任何其他平台或渠道购买勤工助学的虚拟产品或服务。对于用户使用非官方提供或者售卖的直豆、道具等虚拟产品或服务造成的任何损失，勤工助学不对其负责；因在其他平台充值或找他人代充等方式购买产品或服务导致勤工助学遭受损失的，用户应当承担违约责任并赔偿损失。&nbsp;</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">7.用户应通过勤工助学软件使用相关服务，未经许可，不得通过其他第三方工具或运营平台获取勤工助学服务，包括但不限于通过第三方软件登录勤工助学账号、发布岗位、浏览岗位、收发简历等。如因用户使用第三方软件导致相关信息泄漏的，勤工助学不承担任何责任，且用户还应承担由此给勤工助学造成的损失。</span>
    </p>
    <p><strong style="color: rgb(0, 0, 0);">五、有限责任条款</strong></p>
    <p><span style="color: rgb(0, 0, 0);">1.钟信教育公司将尽力为用户提供提供安全、及时、准确、高质量的服务，但不保证一定能满足用户的要求和期望，也不保证服务不会中断，对服务的及时性、安全性、准确性都不作保证。除非另有约定，否则用户因无法使用勤工助学服务，或使用服务未达到心理预期的，勤工助学不承担责任。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">2.对于用户通过钟信教育公司提供的服务传送的内容，勤工助学会尽合理努力按照国家有关规定严格审查，但无法完全控制经由软件/网站服务传送的内容，不保证内容的正确性、完整性或品质。因此用户在使用勤工助学服务时，可能会接触到令人不快、不适当或令人厌恶的内容。在任何情况下，勤工助学均不为用户经由软件/网站服务以张贴、发送电子邮件或其它方式传送的任何内容负责。但勤工助学有权依法停止传输任何前述内容并采取相应行动，包括但不限于暂停用户使用软件/网站服务的全部或部分，保存有关记录，并根据国家法律法规、相关政策在必要时向有关机关报告并配合有关机关的行动。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">3.对于勤工助学提供的各种第三方广告信息、链接、资讯等（如有），勤工助学不保证其内容的正确性、合法性或可靠性，相关责任由广告主承担；并且，对于用户经由勤工助学服务与广告主进行联系或商业往来，完全属于用户和广告主之间的行为，与勤工助学无关。对于前述商业往来所产生的任何损害或损失，勤工助学不承担任何责任。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">4.对于用户上传的照片、资料、证件、视频、内容及图片等，勤工助学已采用相关措施并已尽合理努力进行审核，但不保证其内容的正确性、合法性或可靠性，相关责任由上传上述内容的用户承担。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">5.用户应对勤工助学上的其他用户发布的内容自行加以判断，并承担因使用内容而引起的所有风险，包括但不限于因对内容的正确性、完整性或实用性的依赖而产生的风险。钟信教育公司无法且不会对因前述风险而导致的任何损失或损害承担责任。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">6.是否使用软件/网站服务下载或取得任何资料应由用户自行考虑并自负风险，因任何资料的下载而导致的用户电脑系统的任何损坏或数据丢失等后果，勤工助学不承担任何责任。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">7.对于钟信教育公司在线上或线下策划、发起、组织或是承办的任何招聘相关的活动（包括但不限于收取费用以及完全公益的活动），勤工助学不对上述招聘效果向用户作出任何保证或承诺，也不担保活动期间用户自身行为的合法性、合理性。由此产生的任何对于用户个人或者他人的人身或者是名誉以及其他损害，应由行为实施主体承担责任。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">8.对于用户的投诉，勤工助学将尽合理努力进行核实和处理，但不保证一定能满足投诉者的要求。勤工助学有权决定是否向公众或向被投诉者公开投诉内容。对于投诉内容侵犯用户隐私权、名誉权等合法权利的，所有法律责任由投诉者承担，与勤工助学无关。</span>
    </p>
    <p><strong style="color: rgb(0, 0, 0);">六、用户权利</strong></p>
    <p><span style="color: rgb(0, 0, 0);">用户对于自己的个人信息享有以下权利：</span></p>
    <p><span style="color: rgb(0, 0, 0);">1.随时查询及请求阅览，但因极少数特殊情况（如被网站加入黑名单等）无法查询及提供阅览的除外；</span></p>
    <p><span style="color: rgb(0, 0, 0);">2.随时请求补充或更正，但因极少数特殊情况（如网站或有关机关为司法程序保全证据等）无法补充或更正的除外；</span></p>
    <p><span style="color: rgb(0, 0, 0);">3.随时请求注销勤工助学账号。关于注销帐号的相关规则和流程，请参见《用户注销协议》。您注销成功后，我们将根据法律法规的要求尽快删除您的个人信息或作匿名化处理。</span>
    </p>
    <p><strong style="color: rgb(0, 0, 0);">七、用户应承诺其平台使用行为遵守以下规定</strong></p>
    <p><span style="color: rgb(0, 0, 0);">1.本协议所称“平台使用”是指用户使用勤工助学平台服务所进行的任何行为，包括但不限于注册、登录、认证、查看开聊、账号管理、发布招聘信息、邀约面试以及其他通过勤工助学或店长直聘账号在勤工助学平台所进行的一切行为。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">2.钟信教育公司提醒用户在使用勤工助学服务时，应遵守《民法典》《中华人民共和国合同法》《中华人民共和国著作权法》《全国人民代表大会常务委员会关于维护互联网安全的决定》《中华人民共和国保守国家秘密法》《中华人民共和国电信条例》《中华人民共和国计算机信息系统安全保护条例》《中华人民共和国计算机信息网络国际联网管理暂行规定》《计算机信息系统国际联网保密管理规定》《互联网信息服务管理办法》《计算机信息网络国际联网安全保护管理办法》《互联网电子公告服务管理规定》《网络安全法》《中华人民共和国劳动法》及《中华人民共和国劳动合同法》</span><span
        style="color: rgb(51, 51, 51);">《网络信息内容生态治理规定》</span><span style="color: rgb(0, 0, 0);">等相关中国法律法规的规定。</span></p>
    <p><span style="color: rgb(0, 0, 0);">3.在任何情况下，如果钟信教育公司有理由认为用户使用勤工助学服务过程中的任何行为，包括但不限于用户的任何言论和其它行为违反或可能违反上述法律和法规的任何规定，钟信教育公司可在任何时候不经任何事先通知终止向该用户提供服务。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">4.用户承诺在使用勤工助学软件期间，遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等 七条底线。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">5.您理解并同意，勤工助学平台仅为用户提供招聘信息分享、传播及获取招聘、求职机会的平台，您必须为自己的注册、认证账号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。关于用户通过勤工助学平台上传、发布信息的具体规则，应阅读并遵守</span><a
        href="/postrules" rel="noopener noreferrer"
        target="_blank" style="color: rgb(65, 74, 96);">《勤工助学岗位信息发布规则》</a><span style="color: rgb(0, 0, 0);">。</span>
    </p>
    <p><strong style="color: rgb(0, 0, 0);">八、禁止用户利用勤工助学从事下列行为</strong></p>
    <p><span style="color: rgb(0, 0, 0);">通过勤工助学的服务时在勤工助学的网页上或者利用勤工助学的服务制作、复制、发布、传播违反国家相关法律法规及七条底线 、九不准管理规定的违法和不良信息，主要表现为：</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">1.反对宪法所确定的基本原则的。</span></p>
    <p><span style="color: rgb(0, 0, 0);">2.危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的。</span></p>
    <p><span style="color: rgb(0, 0, 0);">3.损害国家荣誉和利益的；煽动民族仇恨、民族歧视、破坏民族团结的。</span></p>
    <p><span style="color: rgb(0, 0, 0);">4.破坏国家宗教政策，宣扬邪教和封建迷信的。</span></p>
    <p><span style="color: rgb(0, 0, 0);">5.散布谣言，扰乱社会秩序，破坏社会稳定的。</span></p>
    <p><span style="color: rgb(0, 0, 0);">6.散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的。</span></p>
    <p><span style="color: rgb(0, 0, 0);">7.侮辱或者诽谤他人，侵害他人合法权利的。</span></p>
    <p><span style="color: rgb(0, 0, 0);">8.含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或有悖道德、令人反感的内容的。</span></p>
    <p><span style="color: rgb(0, 0, 0);">9.含有中国法律、法规、规章、条例以及任何具有法律效力的规范所限制或禁止的其它内容的。</span></p>
    <p><span style="color: rgb(0, 0, 0);">10.使用勤工助学服务的过程中，以任何方式危害求职者合法权益的。</span></p>
    <p><span style="color: rgb(0, 0, 0);">11.冒充任何人或机构，包含但不限于冒充勤工助学工作人员或以虚伪不实的方式陈述或谎称与任何人或机构有关的。</span></p>
    <p><span style="color: rgb(0, 0, 0);">12.发布、传播侵犯任何人的肖像权、名誉权、隐私权、专权、商标权、著作权、商业秘密的信息或言论的。</span></p>
    <p><span style="color: rgb(0, 0, 0);">13.将病毒或其它计算机代码、档案和程序，加以上载、张贴、发送电子邮件或以其它方式传送的。</span></p>
    <p><span style="color: rgb(0, 0, 0);">14,跟踪或以其它方式骚扰其他用户的。</span></p>
    <p><span style="color: rgb(0, 0, 0);">15.未经合法授权而截获、篡改、收集、储存或删除他人个人信息、电子邮件或其它数据资料，或将获知的此类资料用于任何非法或不正当目的。</span></p>
    <p><span style="color: rgb(0, 0, 0);">16.以任何方式干扰或企图干扰勤工助学的任何产品、任何部分或功能的正常运行，或者制作、发布、传播上述工具、方法等。</span></p>
    <p><span
        style="color: rgb(0, 0, 0);">17.未能按照勤工助学平台的流程、规则进行注册、认证或使用本服务的，违反本服务功能限制或运营策略，或采取任何措施规避前述流程、规则、限制或策略的。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">18.未经钟信教育公司的许可使用插件、外挂或通过其他第三方工具、运营平台或任何服务接入本服务和相关系统的。</span></p>
    <p><span style="color: rgb(0, 0, 0);">19.利用勤工助学账号或勤工助学平台服务从事，包括但不限于欺诈、传销、刷流量、好评、违法物品营销等任何违法兼职或犯罪活动的。</span></p>
    <p><span style="color: rgb(0, 0, 0);">20.仿冒、混淆他人账号昵称、头像、功能介绍或发布招聘内容等，或冒充、利用他人名义对外招聘的。</span></p>
    <p><span style="color: rgb(0, 0, 0);">21.未经钟信教育公司的许可，以任何目的自行或授权、允许、协助任何第三人对平台内的任何信息内容进行非法获取，用于商业用途或其他任何目的。“非法获取”是指采用包括但不限于“蜘蛛”(spider)程序、爬虫程序、拟人程序等非真实用户或避开、破坏技术措施等非正常浏览的手段、方式，读取、复制、转存、获得数据和信息内容的行为。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">22.为任何注册用户或非注册用户提供自动登录到勤工助学平台、代办或协助他人代办身份认证服务的或代售身份认证所需的相关材料或凭据等。</span></p>
    <p><span style="color: rgb(0, 0, 0);">23.其他任何导致或可能导致钟信教育公司与第三方产生纠纷、争议或诉讼的行为。</span></p>
    <p><strong style="color: rgb(0, 0, 0);">九、特别规定</strong></p>
    <p><span style="color: rgb(0, 0, 0);">1.用户如违反本协议第八条，勤工助学有权在任何时候不经任何事先通知暂停或终止向该用户提供服务。</span></p>
    <p><span style="color: rgb(0, 0, 0);">2.用户有下列行为或发布/散布/传播如下相关信息的，勤工助学在发现或接到投诉后，有权采取冻结账户、升级认证或以其他方式暂停向该用户提供服务，并要求用户承担相应的损害赔偿责任：
    </span></p>
    <p><span style="color: rgb(0, 0, 0);">（1）涉及广告（寻求合作）、传销或直销等内容</span></p>
    <p><span style="color: rgb(0, 0, 0);">（2）涉及色情、淫秽内容</span></p>
    <p><span style="color: rgb(0, 0, 0);">（3）涉及违法/政治敏感内容</span></p>
    <p><span style="color: rgb(0, 0, 0);">（4）虚假信息，包括但不限于不真实的公司信息、薪资、个人简历、岗位信息等</span></p>
    <p><span style="color: rgb(0, 0, 0);">（5）利用勤工助学提供的服务索取他人隐私</span></p>
    <p><span style="color: rgb(0, 0, 0);">（6）涉及人身攻击或其他侵害他人权益的内容</span></p>
    <p><span style="color: rgb(0, 0, 0);">（7）未成年人工作信息</span></p>
    <p><span style="color: rgb(0, 0, 0);">（8）招聘他人从事违法活动</span></p>
    <p><span style="color: rgb(0, 0, 0);">（9）以培训费、服装费等名义骗取求职者财物</span></p>
    <p><span style="color: rgb(0, 0, 0);">（10）骚扰其他用户</span></p>
    <p><span style="color: rgb(0, 0, 0);">（11）不符合勤工助学相关服务性质的信息，如鸡汤、段子、水贴等</span></p>
    <p><span style="color: rgb(0, 0, 0);">（12）利用本平台可能存在的漏洞恶意充值直豆、获取道具等虚拟产品或服务</span></p>
    <p><span style="color: rgb(0, 0, 0);">（13）在本平台以外的任何第三方平台（包括但不限于淘宝、闲鱼等）售卖直豆、道具等虚拟产品或服务的行为</span></p>
    <p><span style="color: rgb(0, 0, 0);">（14）通过第三方平台或渠道（如淘宝店铺等）购买直豆、道具等虚拟产品或服务</span></p>
    <p><span style="color: rgb(0, 0, 0);">（15）涉嫌拖欠/未按法律规定支付薪资/劳务报酬的，或涉嫌具有其他可能损害劳动者或劳务人员合法权益的。（涉及农民工或涉众的均属于“情节严重”）本平台有权对前述情形进行处置，相关判断方式包括但不限于因上述行为被列入相关政府部门“黑名单”、被多名用户举报投诉或被新闻媒体曝光等情形</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">（16）其他违反法律法规或国家政策以及损害勤工助学及其合法用户之合法权益的行为</span></p>
    <p><span style="color: rgb(0, 0, 0);">3.根据我国现行的法律法规等相关规定，如用户实施前述第（4）项“发布虚假信息”的，包括但不限于用户发布的岗位信息与其实际招聘的岗位不符的，如用户实际招聘的岗位为“保险销售、信用卡销售、理财产品销售、地产中介或销售或劳务派遣”，与其发布的岗位信息在内容、类型或其他方面并非一致或对应的甚至不存在，钟信教育公司随时有权拒绝向该用户提供服务，并可采取其他处理措施，包括但不限于“永久性封禁账号”、“永久性将其设备号、手机号等相关信息冻结”或“永久性加入‘黑名单’”等。</span>
    </p>
    <p><strong style="color: rgb(0, 0, 0);">十、隐私政策</strong></p>
    <p><span style="color: rgb(0, 0, 0);">勤工助学依法保护用户个人信息和隐私信息。有关隐私政策的内容，详见本协议的第二部分 《个人信息保护政策》。</span></p>
    <p><strong style="color: rgb(0, 0, 0);">十一、关于用户在勤工助学的上传或张贴的内容</strong></p>
    <p><span style="color: rgb(0, 0, 0);">1.用户在勤工助学上传或张贴的内容（包括但不限于照片、文字、面试经历及心得评价等），视为用户授予钟信教育公司免费、非独家的使用权，勤工助学有权为展示、传播及推广前述张贴内容的目的，对上述内容进行复制、修改、出版等。该使用权持续至用户书面通知勤工助学不得继续使用，且勤工助学实际收到该等书面通知时止。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">2.因用户上传或张贴的内容侵犯他人权利，而导致任何第三方向钟信教育公司提出侵权或索赔要求的，用户应承担全部责任。</span></p>
    <p><span
        style="color: rgb(0, 0, 0);">3.任何第三方对于用户在勤工助学的公开使用区域张贴的内容进行复制、修改、编辑、传播等行为的，该行为产生的法律后果和责任均由行为人承担，与勤工助学无关。</span>
    </p>
    <p><strong style="color: rgb(0, 0, 0);">十二、关于面试聊天等即时通讯服务</strong></p>
    <p><span style="color: rgb(0, 0, 0);">1.用户在接受勤工助学提供与勤工助学注册用户或勤工助学关联方用户进行提在线开聊、邀约面试等即时通讯服务时，应当遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚，并保证所传输的信息真实性等七条底线。</span>
    </p>
    <p><span
        style="color: rgb(0, 0, 0);">2.用户通过勤工助学平台与他人在线开聊、拨打电话以及视频面试等商务场景下产生的<strong>文字、语音及视频等形式的沟通信息</strong>，勤工助学将会根据法律规定暂时存储，且仅用于投诉举报的处理、安全风控及离线暂存功能的实现。勤工助学对该信息的采集、传输及存储均会采取加密、防泄露等相关措施。通常会在30天后删除。
    </span></p>
    <p><span style="color: rgb(0, 0, 0);">4.为保护其他用户隐私，用户不得将本条规定的其他用户通讯信息，如面试聊天记录等，对外进行公布。如因此造成勤工助学损失，或者侵害其他用户权利的，应当承担违约责任或赔偿责任。</span>
    </p>
    <p><strong style="color: rgb(0, 0, 0);">十三、信息储存和限制</strong></p>
    <p><span style="color: rgb(0, 0, 0);">勤工助学有权制定一般措施及限制，包含但不限于软件服务将保留的电子邮件、聊天讯息、所张贴内容或其他上载内容的最长期间、每个账号可收发沟通讯息的最大数量及可收发的单个消息的大小。通过服务存储或传送之任何信息、通讯资料和其他内容，如被删除或未予储存，勤工助学不承担任何责任。用户同意，长时间未使用的账号，勤工助学有权关闭。同时勤工助学有权自行决定并无论通知用户与否，随时变更这些一般措施及限制。</span>
    </p>
    <p><strong style="color: rgb(0, 0, 0);">十四、结束服务</strong></p>
    <p><span style="color: rgb(0, 0, 0);">用户若反对任何注册条款的内容或对之后注册条款修改有异议，或对勤工助学服务不满，用户有以下权利：不再使用勤工助学服务；结束用户使用勤工助学服务的资格；通知勤工助学停止该用户的服务。结束用户服务的同时，用户使用勤工助学服务的权利立即终止，勤工助学不再对用户承担任何义务。</span>
    </p>
    <p><strong style="color: rgb(0, 0, 0);">十五、禁止商业行为</strong></p>
    <p><span style="color: rgb(0, 0, 0);">1.用户同意不对勤工助学提供的服务或服务的任何部分，进行复制、拷贝、出售、转售或用于任何其他商业目的。</span></p>
    <p><span style="color: rgb(0, 0, 0);">2.禁止通过岗位向应聘者收费，如有不实，我们将结束用户使用勤工助学服务的资格。</span></p>
    <p><strong style="color: rgb(0, 0, 0);">十六、违约责任</strong></p>
    <p><span style="color: rgb(0, 0, 0);">1.用户使用虚假身份信息、公司信息进行注册，发布虚假招聘、求职信息，发布含有传销、色情、反动等严重违法内容，对外传播面试聊天等通讯记录等行为，视为严重违反本协议，应当承担给钟信教育公司造成的经济损失和名誉损失。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">2.因用户通过勤工助学提供的服务提供、张贴或传送内容、违反本服务条款、或侵害他人任何权利而导致任何第三人对勤工助学提出任何索赔或请求，用户应当赔偿勤工助学或其他合作伙伴的损失，包括但不限于赔偿金额、律师费和合理的调查费用等。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">3.用户在投诉其他用户有违法行为或违反本注册条款情形时，投诉者应承担不实投诉所产生的全部法律责任。如侵犯他人的合法权益，投诉人应独立承担全部法律责任。如给勤工助学造成损失的，投诉人应对勤工助学承担相应的赔偿责任。</span>
    </p>
    <p><strong style="color: rgb(0, 0, 0);">十七、本协议条款的变更和修改</strong></p>
    <p><span style="color: rgb(0, 0, 0);">勤工助学有权依法随时对本协议的任何条款进行变更和修改。一旦发生条款变动，我们将在勤工助学软件内进行更新及提示，或将最新版本的《用户协议及隐私政策》以系统消息、弹窗或邮件的形式发送给用户阅读及确认接收。用户如果不同意条款的修改，应主动停止使用勤工助学或申请注销勤工助学账号，如未使用的付费权益将在注销后清空。否则，如果用户继续使用用户账号，则视为用户已经接受本协议全部条款的修改。</span>
    </p>
    <p><strong style="color: rgb(0, 0, 0);">十八、不可抗力</strong></p>
    <p><span style="color: rgb(0, 0, 0);">1.不可抗力”是指勤工助学不能合理控制、不可预见或即使预见亦无法避免的事件，该事件妨碍、影响或延误勤工助学根据本注册条款履行其全部或部分义务。该事件包括但不限于政府行为、自然灾害、战争、黑客袭击、电脑病毒、网络故障等。不可抗力可能导致勤工助学无法访问、访问速度缓慢、存储数据丢失、用户个人信息泄漏等不利后果。</span>
    </p>
    <p><span
        style="color: rgb(0, 0, 0);">2.遭受不可抗力事件时，勤工助学可中止履行本用户协议项下的义务直至不可抗力的影响消除为止，并且不因此承担违约责任；但应尽最大努力克服该事件，减轻其负面影响。</span>
    </p>
    <p><strong style="color: rgb(0, 0, 0);">十九、通知</strong></p>
    <p><span style="color: rgb(0, 0, 0);">勤工助学向其用户发出的通知，将采用系统消息、弹窗、电子邮件或页面公告等形式。本《用户协议及隐私政策》的条款修改或其他事项变更时，勤工助学可以以上述形式进行通知。</span>
    </p>
    <p><strong style="color: rgb(0, 0, 0);">二十、法律的适用和管辖</strong></p>
    <p><span style="color: rgb(0, 0, 0);">本协议的生效、履行、解释及争议的解决均适用中华人民共和国的现行法律，所发生的争议应提交北京仲裁委员会，其仲裁裁决是终局的。本用户协议因与中华人民共和国现行法律相抵触而导致部分条款无效的，不影响其他条款的效力。</span>
    </p>
    <p><br></p>
    <p class="ql-align-center"><br></p>
    <p class="ql-align-center"><strong style="color: rgb(0, 0, 0);">第二部分 《个人信息保护政策》</strong></p>
    <p><br></p>
    <p><strong style="color: rgb(0, 0, 0);">更新日期：2020年月08日20日</strong></p>
    <p><span style="color: rgb(0, 0, 0);">欢迎您使用我们的产品和服务！我们非常重视您的隐私保护和个人信息保护。本《个人信息保护政策》将帮助您了解以下内容:</span></p>
    <p><br></p>
    <p><span style="color: rgb(0, 0, 0);">一、引言&nbsp;&nbsp;&nbsp;</span></p>
    <p><span style="color: rgb(0, 0, 0);">二、我们如何收集和使用您的个人信息</span></p>
    <p><span style="color: rgb(0, 0, 0);">（一）主要功能及相关个人信息&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
    <p><span style="color: rgb(0, 0, 0);">（二）改进产品和保障求职招聘安全的相关信息&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
    <p><span style="color: rgb(0, 0, 0);">（三）您授权我们调用设备权限的情形</span></p>
    <p><span style="color: rgb(0, 0, 0);">（四）第三方SDK收集信息的情形&nbsp;&nbsp;</span></p>
    <p><span style="color: rgb(0, 0, 0);">（五）个性化展示及推送服务</span></p>
    <p><span style="color: rgb(0, 0, 0);">（六）征得授权同意的例外&nbsp;</span></p>
    <p><span style="color: rgb(0, 0, 0);">三、我们如何使用cookie和同类技术&nbsp;&nbsp;</span></p>
    <p><span style="color: rgb(0, 0, 0);">四、我们如何共享、转让、公开披露您的个人信息&nbsp;&nbsp;</span></p>
    <p><span style="color: rgb(0, 0, 0);">（一）共享&nbsp;</span></p>
    <p><span style="color: rgb(0, 0, 0);">（二）转让</span></p>
    <p><span style="color: rgb(0, 0, 0);">（三）公开披露</span></p>
    <p><span style="color: rgb(0, 0, 0);">（四）例外情形&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
    <p><span style="color: rgb(0, 0, 0);">五、我们如何保存及保护您的个人信息</span></p>
    <p><span style="color: rgb(0, 0, 0);">六、您的权利&nbsp;&nbsp;</span></p>
    <p><span style="color: rgb(0, 0, 0);">七、我们如何处理未成年人的个人信息&nbsp;</span></p>
    <p><span style="color: rgb(0, 0, 0);">八、您的个人信息如何进行跨境转移&nbsp;&nbsp;&nbsp;</span></p>
    <p><span style="color: rgb(0, 0, 0);">九、本《个人信息保护政策》的更新&nbsp;</span></p>
    <p><span style="color: rgb(0, 0, 0);">十、如何联系我们&nbsp;&nbsp;</span></p>
    <p><span style="color: rgb(0, 0, 0);">十一、争议解决</span></p>
    <p><span style="color: rgb(0, 0, 0);">附录：定义</span></p>
    <p><br></p>
    <p><strong style="color: rgb(0, 0, 0);">一、引言</strong></p>
    <p><span style="color: rgb(0, 0, 0);">&nbsp;&nbsp;为了切实保护勤工助学用户隐私权，优化用户体验，勤工助学根据现行法规及政策，制定本《个人信息保护政策》。本《个人信息保护政策》将详细说明勤工助学在获取、管理及保护用户个人信息方面的政策及措施。本《个人信息保护政策》适用于钟信教育公司向您提供的所有服务，无论您是通过计算机设备、移动终端或其他设备获得的勤工助学服务。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">&nbsp;&nbsp;本《个人信息保护政策》旨在帮助您了解我们会收集哪些数据、为什么收集这些数据，会利用这些数据做些什么及如何保护这些数据。我们希望您在使用勤工助学服务前仔细阅读并明确您已经充分理解、接受本《个人信息保护政策》的内容，希望您可以根据自己的理解做出合适的选择。您一旦开始使用勤工助学平台服务，即表示您认同本《个人信息保护政策》中所述内容。在我们更新本《个人信息保护政策》后，您继续使用我们的产品和/或服务，即意味着您同意本《个人信息保护政策》(含更新版本)内容，并且同意我们按照本《个人信息保护政策》收集、使用、保存和共享您的相关信息。此外，当我们将您的个人信息用于本《个人信息保护政策》未涵盖的用途时，我们会事先征求您的同意。</span>
    </p>
    <p><br></p>
    <p><br></p>
    <p><strong style="color: rgb(0, 0, 0);">二、我们如何收集和使用您的个人信息</strong></p>
    <p><span style="color: rgb(0, 0, 0);">（一）基本功能及相关必要个人信息</span></p>
    <p><span style="color: rgb(0, 0, 0);">在您使用勤工助学提供的以下服务或功能过程中，我们将收集以下必要的相关个人信息，以及部分您可以选择提供的个人信息。</span></p>
    <p><span style="color: rgb(0, 0, 0);">1.注册账号</span></p>
    <p><span style="color: rgb(0, 0, 0);">您首先需要注册一个勤工助学账号成为勤工助学的注册用户。当您注册时，您至少需要向我们提供您本人的</span><strong
        style="color: rgb(0, 0, 0);">手机号码</strong><span style="color: rgb(0, 0, 0);">，我们将通过发送短信验证码的方式来验证您的身份是否有效。如果您不提供这些信息，不影响您通过web或wap页面进行浏览、搜索。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">2.实名认证</span></p>
    <p><strong
        style="color: rgb(0, 0, 0);">首次发布招聘岗位前，您需通过实名认证与实人认证。您需要提供平台审核所需的个人信息包括真实的</strong><strong>姓名、身份证号、性别、年龄</strong>以及<strong>面部识别信息</strong>；同时完善您的非个人信息包括招聘<strong>单位的名称、岗位头衔</strong>，<strong>企业邮箱、工牌及《在职证明》</strong>其中的至少一种信息以证明您可以代表该单位进行招聘。如果您提交的单位首次在勤工助学上发布招聘信息，那么您还需要提供营业执照以验证招聘单位的合法性，认证通过后该岗位可正常对外显示。
    </p>
    <p>3.搜索附近</p>
    <p>基于保护求职者安全，当您作为招聘用户使用勤工助学时或作为求职用户进入特定场景时，我们在首次会通过弹窗申请获取您的<strong>位置信息</strong>（<strong>GPS定位</strong>）；经您授权同意后，再获取您的位置权限，例如您所在的城市、地区等。我们承诺仅收集您当时所处的位置，不会追踪您的行踪轨迹。如果拒绝授权，您仅能使用不需要位置信息的求职功能。
    </p>
    <p>4.创建在线简历</p>
    <p>在您注册成功后，首先您需要完善在线简历（公开），设置您的<strong>头像、填写生日、年龄、工作经历（开始工作时间、公司名称、担任职务、在职时间段、工作内容描述</strong>）、<strong>教育经历</strong>（<strong>毕业学校、学历、在校时间段、在校经历描述</strong>）、<strong>联系方式</strong>（<strong>微信、手机号码、电子邮箱</strong>）、<strong>工作期望</strong>（<strong>期望城市、期望薪资、期望行业、期望岗位</strong>）等基本简历信息。钟信教育公司将根据您的以上信息，根据推荐算法为您展示合适的招聘信息并将您推荐给合适的招聘用户。您的<strong>个人信息（包括手机号、电子邮箱、微信号</strong>）在您没有主动或同意交换的情况下，不会展示给其他用户。
    </p>
    <p>
      您可以在隐私设置中，设置在线简历信息隐藏，隐藏后招聘用户无法再浏览您的在线简历内容。请注意：如果当您的在线简历处于隐藏状态，一旦您主动查看岗位信息的话，您所查看的岗位信息发布者（招聘用户）可看到您隐藏的简历信息（不包括任何联系方式）。</p>
    <p>5.推荐服务</p>
    <p>（1）您可以通过勤工助学软件内的推荐页，浏览到可能会满足您的需求或期望的招聘信息或求职用户。我们会保留您的<strong>关键词搜索记录</strong>或基于您的使用习惯，避免您再重复输入或为您展示与您搜索内容相关岗位或求职用户。请您注意，当您的<strong>关键词搜索</strong>与您的其他信息相互结合使用并可以识别您的身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的<strong>历史搜索记录</strong>一同按照本《个人信息保护政策》对其进行处理与保护。
    </p>
    <p>（2）我们会通过您填写的<strong>求职期望、工作经历，结合</strong>您<strong>的操作记录（如搜索、浏览、关注、收藏及开聊）</strong>形成您的个人画像，再利用算法技术，根据该个人画像为您推送相关招聘信息或求职用户以及将您推荐给其他招聘/求职用户。
    </p>
    <p><strong style="color: rgb(0, 0, 0);">6.在线支付</strong></p>
    <p><strong style="color: rgb(0, 0, 0);">在您选择在线支付购买我们的直豆、虚拟道具、付费权益等增值服务时，您可以选择第三方支付机构所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的购买明细、订单号与交易金额信息与这些支付机构共享以实现其确认您的支付指令并完成支付。</strong>
    </p>
    <p><strong style="color: rgb(0, 0, 0);">7.开聊/面试</strong></p>
    <p><strong style="color: rgb(0, 0, 0);">（1）当您在使用勤工助学，与求职用户或招聘用户进行线上沟通时，根据双方沟通意向，可能需要交换联系方式（包括</strong><strong>电话、微信、电子邮箱</strong>），要求发送<strong>附件简历</strong>文件。在一方主动发起，另一方主动同意的情况下，相互交换联系方式，或将求职用户的<strong>附件简历</strong>发送给招聘用户。
    </p>
    <p>（2）当求职用户与招聘用户在线上达成面试意向，并通过勤工助学提供的约面试功能成功发出面试邀请，用户接受后需要向对方展示<strong>手机号、微信</strong>等常用联系方式，用于线下前往面试地点时候的必要联系。
    </p>
    <p>（二）改进产品和保障求职招聘安全的相关信息</p>
    <p>为了增强勤工助学平台的求职招聘、在线面试等过程中的安全性，尽可能保障您或其他用户在招聘求职过程中的人身、财产安全免遭侵害，我们会收集必要的不涉及到您的个人身份等隐私的信息，包括：</p>
    <p>1.我们可能会收集您网络服务相关的日志信息，如<strong>IP地址、某按钮的点击使用时间和频率、某页面的使用时间和频率、某些关键配置的选项值、崩溃日志、错误日志</strong>，用于将您感兴趣的岗位、求职用户或求职招聘过程中您可能需要的服务信息展示给您，同时有可能用于统计我们产品的用户数量、分析产品的使用状况、网页升级改版、浏览器兼容性检测、排查崩溃原因、减少崩溃情况。
    </p>
    <p>
      2.为了验证您的身份信息，防止他人未经授权访问您的帐号，我们会在您授权同意后，核验您的<strong>面部识别信息</strong>、访问您的<strong>手机设备型号、IP地址、设备软件版本信息</strong>、<strong>设备标识符</strong>。否则将无法利用身份信息验证的方式保障您的账户安全。
    </p>
    <p>3.我们可能会利用身份信息和商务场景下的通讯信息（包括您的<strong>手机号、邮箱地址、微信、沟通记录</strong>），用于实现与招聘求职有关的在线咨询、视频面试等功能、处理您的投诉举报、申诉，以及客服响应用户前的身份核验<strong>（包括您的账号ID、手机号、面部信息）。</strong>
    </p>
    <p><span style="color: rgb(0, 0, 0);">（三）需要您授权同意调取系统权限的情形</span></p>
    <p><span style="color: rgb(0, 0, 0);">基于保护招聘求职过程中的求职者人身安全之考虑，以及满足互联网平台服务普遍存在的风控需求，勤工助学的附加业务功能可能需要您先同意我们调取相关的系统权限以使用您的个人信息。您可通过手机设备上的“我的-设置-隐私设置-权限管理”逐项查看具体的个人信息访问权限及其开启状态，并决定开启或关闭这些权限。若开启这些权限即代表您授权我们收集和使用这些个人信息：</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">1.读取设备权限</span></p>
    <p><span style="color: rgb(0, 0, 0);">当您使用android版本的勤工助学APP时，我们会首次向您申请获取设备权限，目的是正常识别您的</span><strong
        style="color: rgb(0, 0, 0);">手机设备识别码</strong><span style="color: rgb(0, 0, 0);">，以满足风控、审计需求。若您拒绝，我们可能无法保障您正常使用基本业务功能。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">2.读取存储权限</span></p>
    <p><span style="color: rgb(0, 0, 0);">当您使用android版本的勤工助学APP、完善注册信息时，在您同意开启</span><strong
        style="color: rgb(0, 0, 0);">外部存储权限</strong><span
        style="color: rgb(0, 0, 0);">后，才可从本地上传图片、照片或文档至勤工助学APP进行头像设置、</span><strong
        style="color: rgb(0, 0, 0);">简历</strong><span style="color: rgb(0, 0, 0);">上传等操作。否则，您将无法使用与存储卡读取或缓存相关的功能，但您仍可进入勤工助学继续完善您的注册信息。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">3.访问相机权限</span></p>
    <p><strong
        style="color: rgb(0, 0, 0);">我们访问您的相机是为了您可使用二维码扫描、直接拍摄并上传图片，以及在您招聘前的身份认证审核环节，调用您的摄像头，采集您的</strong><strong>面部识别特征，用于身份认证、账号解封、安全核验等功能。</strong>
    </p>
    <p><strong style="color: rgb(0, 0, 0);">4.开启定位权限</strong></p>
    <p><strong style="color: rgb(0, 0, 0);">&nbsp;我们会在求职服务中的特定场景以及切换为招聘用户期间，使用您的</strong><strong>位置信息</strong>来实现（求职者）“查找附近岗位”的业务功能
      ；核实招聘用户发布的岗位地点是否真实，来判断风险的高低，从而保障求职用户的人身安全。但我们承诺仅收集您所处的<strong>位置信息</strong>，例如您所在的省、市、区（县），而不会追踪您的行踪轨迹。如果拒绝开启位置信息，您仍可使用其他求职业务功能。
    </p>
    <p>5.访问相册权限</p>
    <p>通过开启允许访问相册功能，您可以直接选择手机相册内介绍企业的图片上传展示。</p>
    <p>6.访问通讯录权限</p>
    <p>为您实现邀请手机<strong>通讯录</strong>好友加入群聊，需要在您同意授权后，开通此权限，实现您直接从通讯录中选择并导入选定联系人。在此权限下，我们仅会访问您主动选择的<strong>联系人信息</strong>，不会收集、使用或存储您的通讯录中其他的联系人信息。
    </p>
    <p>7.开启通知权限</p>
    <p>为了能够及时向您发送服务通知，我们会在您授权同意后获取该权限，如果不授权该权限，您将无法接收到勤工助学APP向您推送通知的提醒，但不影响其他功能使用。</p>
    <p>8.访问日历权限</p>
    <p>我们访问您的日历，是为了向您提供预约面试等相关功能，并通过您的手机日历将面试时间添加至日程安排。</p>
    <p>9.访问麦克风权限</p>
    <p>
      我们访问您的麦克风是为了在您与其他招聘/求职用户进行开聊时发送语音消息或使用语音转文字功能。我们只会在为您提供特定的业务场景下申请麦克风权限。在您同意开启麦克风权限后，只有您主动点击相关图标时，我们才会通过麦克风功能获取语音信息。</p>
    <p>关闭上述系统权限，即代表您撤回这些授权，则我们将不再继续收集和使用您的这些个人信息，但也无法为您提供与这些授权所对应的相关功能。上述权限的关闭决定并不会影响此前基于您的授权所进行的个人信息的处理。</p>
    <p><span style="color: rgb(0, 0, 0);">（四）通过第三方SDK收集信息的情形</span></p>
    <p><span style="color: rgb(0, 0, 0);">勤工助学的部分附加业务服务或功能，需要使用第三方SDK来协助完成，或者由第三方SDK来直接提供相关服务。这些SDK可能会收集或使用您的以下个人信息，我们会以弹窗或其他形式另行通知，在您授权同意后再获取相关信息。如未取得您的授权，我们将不会收集和使用相关信息，也不会再反复弹窗向您获取授权。目前这些SDK包括：</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">1.活体认证SDK（阿里云）</span></p>
    <p><span style="color: rgb(0, 0, 0);">为了落实《网络安全法》《人力资源市场暂行条例》及《互联网招聘行业公约》关于身份审核的法律规定及行业约定，招聘用户均需通过人脸识别进行实人认证，或账号异常时需要通过面部识别信息完成身份核验以解封账号。我们在您授权同意后，获取您的本机摄像头、相册以及麦克风权限，如不授予该权限，您将无法通过认证或账号解封。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">2.微信</span></p>
    <p><strong style="color: rgb(0, 0, 0);">为实现用户在线支付购买增值服务产品，我们使用微信。在您授权同意后，获取访问您的</strong><strong>本机设备信息（IMEI号）、读写存储、查看网络连接权限</strong>，确保您正常使用在线支付功能。否则，您可能无法购买我们的增值产品或服务。
    </p>
    <p>3.PUSH SDK（极光推送）</p>
    <p>为了实现向您推送系统通知或重要消息，我们会使用push SDK。在您授权同意后，获取访问您的<strong>本机设备信息（IMEI号）、手机型号、系统类型、系统版本、设备手机屏幕尺寸、参数权限</strong>，如果您不希望收到这些通知或信息，可以在终端设备设置中关闭消息通知。
    </p>
    <p>4.友盟SDK</p>
    <p>为了保障平台的运营稳定安全，我们使用友盟SDK，在您授权同意后，向您获取访问您的<strong>本机设备信息、MAC地址及服务功能的使用频次权限</strong>。如不授权，有可能会影响我们利用数据进行功能优化而难以提升用户使用需求。
    </p>
    <p>5.高德地图SDK</p>
    <p>为了确保求职用户看到的招聘岗位位置的真实可信，同时基于网络安全风控的需要，我们通过高德地图SDK，在您授权同意后，获取访问您所在的城市、地区以及<strong>位置信息</strong>的权限。</p>
    <p><span style="color: rgb(0, 0, 0);">（五）个性化展示及推荐</span></p>
    <p><span style="color: rgb(0, 0, 0);">在符合法律法规的前提下，我们可能将您的个人信息经匿名化、汇总、脱敏和加密处理后，形成统计信息或用户画像后向您提供如下个性化展示和精准推荐功能。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">1.个性化内容的展示</span></p>
    <p><span style="color: rgb(0, 0, 0);">（1）针对应届生的个性化服务</span></p>
    <p><span style="color: rgb(0, 0, 0);">当您作为“学生”用户在勤工助学找工作时，我们将根据您填写的</span><strong
        style="color: rgb(0, 0, 0);">工作经历</strong><span
        style="color: rgb(0, 0, 0);">信息提供个性化服务。不具有工作经历的应届生求职用户，将会在勤工助学APP界面顶端看到“校园招聘”的banner，点击进入后即可浏览与应届生求职相关的内容，例如应届生求职攻略等经验、干货。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">2.关闭个性化展示</span></p>
    <p><span
        style="color: rgb(0, 0, 0);">若您不喜欢个性化展示（不含招聘岗位和求职用户信息的正常展示），可点击个性化展示区域或banner上的“叉号”关闭，关闭后将不再为您展示。</span><strong
        style="color: rgb(0, 0, 0);">（温馨提示：</strong><strong>本功能默认开启）</strong></p>
    <p><span style="color: rgb(0, 0, 0);">3</span>.退订个性化推送</p>
    <p><span style="color: rgb(0, 0, 0);">如您不希望再次接收此类push推送，您可以随时通过取消关注、推荐、收藏的方式进行退订，或者若您再也不想接收个性化服务，您可通过【设置】→【隐私设置】→【接收个性化服务】将开关关闭即可退订个性化营销类push的推送。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">（六）征得授权同意的例外</span></p>
    <p><span style="color: rgb(0, 0, 0);">根据相关法律法规的规定，在以下情形中，我们可以在不征得您的授权同意的情况下收集、使用一些必要的个人信息：</span></p>
    <p><span style="color: rgb(0, 0, 0);">（1）与国家安全、国防安全直接相关的；</span></p>
    <p><span style="color: rgb(0, 0, 0);">（2）与公共安全、公共卫生、重大公共利益直接相关的；</span></p>
    <p><span style="color: rgb(0, 0, 0);">（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；</span></p>
    <p><span style="color: rgb(0, 0, 0);">（4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span></p>
    <p><span style="color: rgb(0, 0, 0);">（5）所收集的个人信息是您自行向社会公众公开的；</span></p>
    <p><span style="color: rgb(0, 0, 0);">（6）从合法公开披露的信息中收集到您的个人信息，如从合法的新闻报道、政府信息公开等渠道；</span></p>
    <p><span style="color: rgb(0, 0, 0);">（7）根据您的要求签订和履行合同所必需的；</span></p>
    <p><span style="color: rgb(0, 0, 0);">（8）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</span></p>
    <p><span style="color: rgb(0, 0, 0);">（9）用于维护勤工助学平台产品和/或服务的安全稳定运行所必需的，包括发现、处置产品或服务的故障；</span></p>
    <p><span style="color: rgb(0, 0, 0);">（10）法律法规规定的其他情形；</span></p>
    <p><span style="color: rgb(0, 0, 0);">如果您对我们收集和使用您的个人信息有任何疑问或需要提供进一步的信息，请通过本《个人信息保护政策》公布的联系方式与我们联系。根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</span>
    </p>
    <p><br></p>
    <p><strong style="color: rgb(0, 0, 0);">三、我们如何使用cookie和同类技术</strong></p>
    <p><span style="color: rgb(0, 0, 0);">为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 cookie 的小数据文件。cookie 通常包含用户身份标识符、城市名称以及一些字符。cookie主要的功能是便于您使用网站产品和服务，以及帮助网站统计独立访客数量等。运用cookie技术，我们能够为您提供更加周到的服务。 我们不会将 cookie 用于本《个人信息保护政策》所述目的之外的任何用途。您可根据自己的偏好管理或删除 cookie。有关详情，请参见 aboutcookies.org。您可以清除计算机上保存的所有 cookie，大部分网络浏览器都设有阻止 cookie 的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置，但您可能因为该等修改，无法登录或使用依赖于cookie的百度提供的服务或功能。 您可以通过更改您的浏览器设置限制勤工助学对cookie的使用。以chrome浏览器为例，您可以在chrome浏览器右上方的下拉菜单的“浏览器设置”中，通过“设置-高级-清除浏览数据”，选择清除您的cookie。</span>
    </p>
    <p><br></p>
    <p><strong style="color: rgb(0, 0, 0);">四、我们如何共享、转让、公开披露您的个人信息</strong></p>
    <p><span style="color: rgb(0, 0, 0);">我们不会向第三方共享、转让您的个人信息，除非经过您本人事先授权同意，或者共享、转让的个人信息是去标识化处理后的信息，且共享第三方无法重新识别此类信息的自然人主体。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">（一）共享</span></p>
    <p><span
        style="color: rgb(0, 0, 0);">我们会以高度的勤勉义务对待您的信息。除以下情形外，未经您同意，我们不会与除钟信教育公司外的任何第三方公司、组织和个人分享您的信息：</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">1.在勤工助学、店长直聘及看准网之间共享</span></p>
    <p><span style="color: rgb(0, 0, 0);">为保障用户信息在勤工助学与店长直聘的展示一致，以及实现店长直聘账号与勤工助学账号均可在对方平台登录并使用的功能，方便我们统一管理您的信息，我们会经您授权同意后获取您的的个人信息</span><strong
        style="color: rgb(0, 0, 0);">及相关数据信息</strong><span style="color: rgb(0, 0, 0);">，包括但不限于您的</span><strong
        style="color: rgb(0, 0, 0);">ID信息</strong><span style="color: rgb(0, 0, 0);">，如</span><strong
        style="color: rgb(0, 0, 0);">手机号码、头像、昵称、性别、年龄</strong><span style="color: rgb(0, 0, 0);">等，您的认证信息，如身份标识、企业信息、招聘岗位等，前述信息将在勤工助学、店长直聘及看准网等关联平台之间进行同步。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">2.为实现特定功能而与业务合作伙伴共享</span></p>
    <p><span style="color: rgb(0, 0, 0);">当软件服务提供商、智能设备提供商或系统服务提供商与我们联合为您提供服务时，当您需要使用</span><strong
        style="color: rgb(0, 0, 0);">地理位置</strong><span style="color: rgb(0, 0, 0);">功能时，为实现这一功能，我们可能会收集您的</span><strong
        style="color: rgb(0, 0, 0);">位置信息或相关设备信息（包括硬件型号、操作系统版本号、国际移动设备身份识别码（IMEI）、网络设备硬件地址（MAC）及IDFA）</strong><span
        style="color: rgb(0, 0, 0);">。我们的授权合作伙伴包括以下类型：</span></p>
    <p><span style="color: rgb(0, 0, 0);">（1）服务平台或服务提供商。勤工助学各产品接入了丰富的第三方服务。当您选择使用该第三方服务时，您授权我们将该信息提供给第三方服务平台或服务提供商，以便其基于相关信息为您提供服务，包括认证服务提供商，当您绑定勤工助学与认证平台账号时，我们将弹出授权确认页面，在向您告知收集上述信息并得到您授权的情况下，我们可能会将您的</span><strong
        style="color: rgb(0, 0, 0);">简历信息</strong><span style="color: rgb(0, 0, 0);">提供给认证平台，用于审核您的信用状况。</span></p>
    <p><span style="color: rgb(0, 0, 0);">（2）软硬件/系统服务提供商。当第三方软硬件/系统产品或服务与勤工助学的产品或服务结合为您提供服务时，经您授权，我们会向第三方软硬件/系统服务提供商提供您必要的个人信息，以便您使用服务，或用于我们分析产品和服务使用情况，来提升您的使用体验。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">3.关联平台汇聚融合</span></p>
    <p><strong style="color: rgb(0, 0, 0);">为了将您推荐给更多的招聘企业、向您展示更加丰富的招聘/求职信息，勤工助学将与店长直聘平台打通，进而实现资源/信息的汇聚融合
      。您应当同意授权勤工助学将您的全部信息及数据同步给店长直聘以及其他关联平台，否则，您将无法通过店长直聘账号登录勤工助学平台以及使用勤工助学服务，但您已公开的信息可能仍会展示在相关平台。</strong></p>
    <p><span style="color: rgb(0, 0, 0);">4.我们承诺在共享过程中，尽最大可能保障您的数据和隐私不受侵害，并以不低于本《个人信息保护政策》所要求的保密和安全措施来处理该些信息。如果您拒绝本条款的内容，请通过本《个人信息保护政策》公布的联系方式与我们联系。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">5.我们与之共享个人信息的其他公司、组织和个人（如有），我们会与其签署严格的保密协议，并要求第三方以不低于本《个人信息保护政策》所要求的保密和安全措施来处理该些信息。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">（二）转让</span></p>
    <p><span style="color: rgb(0, 0, 0);">我们不会将您的个人信息转让给除钟信教育公司外的任何公司、组织和个人，但以下情形除外：</span></p>
    <p><span style="color: rgb(0, 0, 0);">1.事先获得您的明确授权或同意；</span></p>
    <p><span style="color: rgb(0, 0, 0);">2.满足法律法规、法律程序的要求或强制性的政府要求或司法裁定；</span></p>
    <p><span style="color: rgb(0, 0, 0);">3.如果我们或我们涉及合并、分立、清算、资产或业务的收购或出售等交易，您的个人信息有可能作为此类交易的一部分而被转移，我们将确保该等信息在转移时的机密性，并要求新的持有您个人信息的公司、组织继续受此个人信息保护政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">（三）公开披露</span></p>
    <p><span style="color: rgb(0, 0, 0);">我们仅会在以下情形下，公开披露您的个人信息：</span></p>
    <p><span style="color: rgb(0, 0, 0);">1.获得您的明确同意；</span></p>
    <p><span style="color: rgb(0, 0, 0);">2.基于法律法规、法律程序、诉讼或政府主管部门强制性要求下。</span></p>
    <p><span style="color: rgb(0, 0, 0);">（四）例外情形</span></p>
    <p><span style="color: rgb(0, 0, 0);">在以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</span></p>
    <p><span style="color: rgb(0, 0, 0);">1.与国家安全、国防安全直接相关的；</span></p>
    <p><span style="color: rgb(0, 0, 0);">2.与公共安全、公共卫生、重大公共利益直接相关的；</span></p>
    <p><span style="color: rgb(0, 0, 0);">3.与犯罪侦查、起诉、审判和判决执行等直接相关的；</span></p>
    <p><span style="color: rgb(0, 0, 0);">4.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span></p>
    <p><span style="color: rgb(0, 0, 0);">5.您自行向社会公众公开的个人信息；</span></p>
    <p><span style="color: rgb(0, 0, 0);">6.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</span></p>
    <p><span style="color: rgb(0, 0, 0);">7.根据个人信息主体要求签订和履行合同所必需的；</span></p>
    <p><span style="color: rgb(0, 0, 0);">8.用于维护所提供的产品或服务的安全稳定运行所必需的，包括发现、处置产品或服务的故障；</span></p>
    <p><span style="color: rgb(0, 0, 0);">9.法律法规规定的其他情形。</span></p>
    <p><span style="color: rgb(0, 0, 0);">10.根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。</span>
    </p>
    <p><br></p>
    <p><strong style="color: rgb(0, 0, 0);">五、我们如何保存及保护您的个人信息</strong></p>
    <p><span style="color: rgb(0, 0, 0);">(一) 保存信息</span></p>
    <p><span style="color: rgb(0, 0, 0);">1.信息存储</span></p>
    <p><span style="color: rgb(0, 0, 0);">您在使用勤工助学产品及服务期间，我们将持续为您保存您的个人信息。如果您注销账户或主动删除上述信息，我们将依据《网络安全法》等相关法律法规的规定保存您的信息。在您注销帐户或主动删除上述信息后，我们不会再对您的个人信息进行商业化使用，但我们可能会对您的个人信息进行删除或匿名化处理后使用。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">2.保存地域</span></p>
    <p><span style="color: rgb(0, 0, 0);">我们承诺，目前您的个人信息仅储存于中华人民共和国境内。</span></p>
    <p><span style="color: rgb(0, 0, 0);">3.例外情况</span></p>
    <p><span style="color: rgb(0, 0, 0);">一般而言，我们仅为实现目的所必需的合理时间保留您的个人信息，下列情况下，我们有可能因需符合法律要求，更改个人信息的存储时间：</span></p>
    <p><span style="color: rgb(0, 0, 0);">（1）为遵守法律法规等有关规定的适用；</span></p>
    <p><span style="color: rgb(0, 0, 0);">（2）为遵守法院判决、裁定或其他法律程序的规定；</span></p>
    <p><span style="color: rgb(0, 0, 0);">（3）为遵守相关政府机关或法定授权组织的要求；</span></p>
    <p><span style="color: rgb(0, 0, 0);">（4）我们有理由确信需要遵守法律法规等有关规定；</span></p>
    <p><span style="color: rgb(0, 0, 0);">（5）为执行相关服务协议或本《个人信息保护政策》维护社会公共利益</span></p>
    <p><span style="color: rgb(0, 0, 0);">（6）为保护我们及我们的客户、用户或雇员的人身财产安全；</span></p>
    <p><span style="color: rgb(0, 0, 0);">（7）其他合法权益所合理必需的用途。</span></p>
    <p><span style="color: rgb(0, 0, 0);">4.当我们的产品或服务发生停止运营的情形时，我们将按照法律规定向用户进行通知，通知方式包括但不限于消息推送、平台公告等，并在合理期限内删除或匿名化处理您的个人信息。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">(二) 保护措施</span></p>
    <p><span style="color: rgb(0, 0, 0);">1.我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受SSL协议加密保护；我们同时对勤工助学网站提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全培训课程，加强员工对于保护个人信息重要性的认识。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">2.我们从组织建设、制度设计、人员管理、产品技术等方面多维度提升整个系统的安全性。目前，我们的重要信息系统已经通过网络安全等级保护的测评。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">3.我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本《个人信息保护政策》所述目的所需的期限内保留您的个人信息（除非法律有强制的存留要求）。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">4.互联网并非绝对安全的环境，使用勤工助学平台服务时，我们强烈建议您不要使用非勤工助学平台推荐的通信方式发送您的信息。您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、分享时，您可以自主选择沟通、分享的对象，作为能够看到您的联络方式、交流信息或分享内容等相关信息的第三方。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">5.在使用勤工助学服务进行沟通时，请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即通过第十章公布的联系方式联络我们，以便我们根据您的申请采取相应措施。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">6.请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息，如您在评论、沟通时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">(三)&nbsp;安全事件通知</span></p>
    <p><span style="color: rgb(0, 0, 0);">1.我们会制定网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施，并按照规定向有关主管部门报告。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">2.个人信息泄露、毁损、丢失属于公司级特大安全事件，我们会负责定期组织工作组成员进行安全预案演练，防止此类安全事件发生。若一旦不幸发生，我们将按照最高优先级启动应急预案，由安全部、政府关系部、法务部等多个部门组成应急响应小组，在最短时间内追溯原因并减少损失。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">3.在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时通过软件内部提醒、邮件、短信或电话等方式向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们将及时将事件相关情况通过上述方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span>
    </p>
    <p><br></p>
    <p><strong style="color: rgb(0, 0, 0);">六、您的权利</strong></p>
    <p><span style="color: rgb(0, 0, 0);">按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</span></p>
    <p><span style="color: rgb(0, 0, 0);">(一) 访问和修改您的个人信息</span></p>
    <p><span style="color: rgb(0, 0, 0);">1.访问修改姓名</span></p>
    <p><span style="color: rgb(0, 0, 0);">尚未完成实名认证的，可以在我的&gt;在线简历&gt;姓名中点击进行修改；</span></p>
    <p><span style="color: rgb(0, 0, 0);">已经完成实名认证的求职用户，可以在我的&gt;在线简历中修改</span></p>
    <p><span style="color: rgb(0, 0, 0);">已经完成实名认证的招聘用户，可以在我的&gt;招聘管理中修改</span></p>
    <p><span style="color: rgb(0, 0, 0);">（每位用户三个月内只能修改一次）</span></p>
    <p><span style="color: rgb(0, 0, 0);">2.访问和修改性别</span></p>
    <p><span style="color: rgb(0, 0, 0);">尚未完成实名认证的求职用户，可以在我的&gt;在线简历&gt;性别更改；</span></p>
    <p><span style="color: rgb(0, 0, 0);">已经完成实名认证的用户，不能修改性别。</span></p>
    <p><span style="color: rgb(0, 0, 0);">3.访问和修改简历内容</span></p>
    <p><span style="color: rgb(0, 0, 0);">求职用户可以在 我的&gt;在线简历 中修改简历内容</span></p>
    <p><span style="color: rgb(0, 0, 0);">求职用户可以在 我的&gt;隐私设置 中修改简历 隐藏或展示</span></p>
    <p><span style="color: rgb(0, 0, 0);">求职用户可以在 我的&gt;附件简历 增加新的附件简历文件或删除已有的附件简历文件</span></p>
    <p><span style="color: rgb(0, 0, 0);">4.访问和修改手机号</span></p>
    <p><span style="color: rgb(0, 0, 0);">用户可以在 我的&gt;设置&gt;修改手机号 中更换手机号</span></p>
    <p><span style="color: rgb(0, 0, 0);">5.访问和修改微信号</span></p>
    <p><span style="color: rgb(0, 0, 0);">招聘用户可以在 我的&gt;招聘设置&gt;微信号中修改</span></p>
    <p><span style="color: rgb(0, 0, 0);">求职用户可以在 我的&gt;在线简历&gt;点击姓名&gt;微信号中添加修改</span></p>
    <p><span style="color: rgb(0, 0, 0);">6.访问和修改住址</span></p>
    <p><span style="color: rgb(0, 0, 0);">求职用户可以在推荐列表&gt;排序方式&gt;附近 &gt; 设置/更改住址 来添加或修改住址（需要7.01版本以上）</span></p>
    <p><span style="color: rgb(0, 0, 0);">7.如果您无法通过上述链接访问该等个人信息，您可以通过本《个人信息保护政策》公布的联系方式与我们联系。</span></p>
    <p><span style="color: rgb(0, 0, 0);">8.您在使用我们的产品和服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，请通过本《个人信息保护政策》的“十、如何联系我们”中公布的联系方式与我们联系。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">9.您发现我们处理的关于您的个人信息有错误时，您有权对错误或不完整的信息作出更正或更新，您可以通过上述段落中列明的方式提出更正或补充申请。为保障安全，我们将在您行使更正权前对您的身份进行验证。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">(二) 删除您的个人信息</span></p>
    <p><span
        style="color: rgb(0, 0, 0);">在以下情形中，您可以向我们提出删除个人信息的请求，您可以通过“1.访问和修改您的个人信息”中列明的方式或本《个人信息保护政策》公布的联系方式随时与我们联系：</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">1.如果我们违反法律法规或与您的约定收集、使用、与他人共享或转让您的个人信息；</span></p>
    <p><span style="color: rgb(0, 0, 0);">2.如果我们违反法律法规规定或与您的约定，公开披露您的个人信息，您有权要求我们立即停止公开披露的行为，并发布通知要求相关接收方删除相应的信息。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">3.我们没有征求您的明确同意，收集了您的个人信息。</span></p>
    <p><span style="color: rgb(0, 0, 0);">4.我们违反了与您的约定来使用和处理您的个人信息。</span></p>
    <p><span style="color: rgb(0, 0, 0);">5.您注销了勤工助学帐号、卸载或者不再使用我们的产品（或服务）。</span></p>
    <p><span style="color: rgb(0, 0, 0);">6.我们停止对您提供服务。</span></p>
    <p><span style="color: rgb(0, 0, 0);">(三) 改变授权范围</span></p>
    <p><span style="color: rgb(0, 0, 0);">1、改变或撤回敏感信息权限</span></p>
    <p><span style="color: rgb(0, 0, 0);">您可以在设备本身操作系统中关闭地理位置、通讯录、摄像头、麦克风等权限改变同意范围或撤回您的授权。</span></p>
    <p><span style="color: rgb(0, 0, 0);">2、拒绝接受推送及营销信息</span></p>
    <p><span style="color: rgb(0, 0, 0);">您可以在电话中明确表示拒绝或通过手机设置关闭“推送通知”以及短信回复“TD”的方式拒绝接收广告信息。</span></p>
    <p><span style="color: rgb(0, 0, 0);">3、请您理解，特定的业务功能和服务将需要您的信息才能完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">(四) 注销您的账户</span></p>
    <p><span style="color: rgb(0, 0, 0);">您可以在我们产品的“帮助与反馈&gt;账号管理&gt;注销账号”中直接申请注销账户，也可以通过本《个人信息保护政策》公布的联系方式，申请注销勤工助学账号。具体操作流程及提示注意等相关内容以《注销协议》载明的为准。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">(五) 提前获知产品和服务停止运营</span></p>
    <p><span style="color: rgb(0, 0, 0);">勤工助学愿一直陪伴您，若因特殊原因导致勤工助学平台被迫停止运营，我们将按照法律法规的要求，在产品和/或服务的主页面或站内信或向您发送电子邮件或其他合适的能触达您的方式通知您，并将停止对您个人信息的收集，同时会按照法律规定对所持有的您的个人信息进行删除或匿名化处理等。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">(六) 响应您的上述请求</span></p>
    <p><span style="color: rgb(0, 0, 0);">1.为保障安全，您需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</span></p>
    <p><span style="color: rgb(0, 0, 0);">2.对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">(七) 响应请求的例外</span></p>
    <p><span style="color: rgb(0, 0, 0);">在以下情形中，按照法律法规要求，我们将无法响应您的上述请求：</span></p>
    <p><span style="color: rgb(0, 0, 0);">1.与国家安全、国防安全直接相关的；</span></p>
    <p><span style="color: rgb(0, 0, 0);">2.与公共安全、公共卫生、重大公共利益直接相关的</span></p>
    <p><span style="color: rgb(0, 0, 0);">3.与犯罪侦查、起诉、审判和执行判决等直接相关的</span></p>
    <p><span style="color: rgb(0, 0, 0);">4.证据表明您可能存在明显恶意、滥用权力及占用平台资源、辱骂客服人员等相关情形的（如您的请求将危害公共安全）</span></p>
    <p><span style="color: rgb(0, 0, 0);">5.您和其他人合法权益，或您的请求超出了一般技术手段和商业成本可覆盖的范围）</span></p>
    <p><span style="color: rgb(0, 0, 0);">6.响应个人信息主体的请求将导致您或其他个人、组织的合法权益受到严重损害的；</span></p>
    <p><span style="color: rgb(0, 0, 0);">7.涉及商业秘密的。</span></p>
    <p><br></p>
    <p><strong style="color: rgb(0, 0, 0);">七、我们如何处理未成年人的个人信息</strong></p>
    <p><span style="color: rgb(0, 0, 0);">勤工助学非常重视对未成年人信息的保护。</span>基于我们的产品、网站和服务的性质，勤工助学拒绝年龄未满16周岁的用户使用勤工助学平台。
    </p>
    <p><br></p>
    <p><strong style="color: rgb(0, 0, 0);">八、您的个人信息如何进行跨境转移</strong></p>
    <p><strong style="color: rgb(0, 0, 0);">您的个人信息仅存储在中国境内。</strong></p>
    <p><br></p>
    <p><strong style="color: rgb(0, 0, 0);">九、本《个人信息保护政策》的更新</strong></p>
    <p><span style="color: rgb(0, 0, 0);">我们可能适时修订本《个人信息保护政策》内容。如该等变更会导致您在本《个人信息保护政策》项下权利的实质变化，我们将在变更生效前，通过系统推送、电子邮件、短信或页面公告等方式通知您。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">若您不同意该等变更应停止使用勤工助学平台产品和服务，或通过本《个人信息保护政策》公布的联系方式要求暂时封停您的账号。若您继续使用我们的产品或服务，即表示您同意受修订后的本《个人信息保护政策》的约束；</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">本《个人信息保护政策》所指的重大变更包括但不限于：</span></p>
    <p><span style="color: rgb(0, 0, 0);">1.我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span></p>
    <p><span style="color: rgb(0, 0, 0);">2.个人信息共享、转让或公开披露的主要对象发生变化；</span></p>
    <p><span style="color: rgb(0, 0, 0);">3.您参与个人信息处理方面的权利及其行使方式发生重大变化；</span></p>
    <p><span style="color: rgb(0, 0, 0);">4.其他可能对您的个人信息权益产生重大影响的变化时；</span></p>
    <p><span style="color: rgb(0, 0, 0);">5.个人信息出境情况发生变更时。</span></p>
    <p><br></p>
    <p><strong style="color: rgb(0, 0, 0);">十、如何联系我们</strong></p>
    <p><span style="color: rgb(0, 0, 0);">如果您对本《个人信息保护政策》及我们对您的个人信息的处理有任何疑问、意见、建议，请通过</span><strong
        style="color: rgb(0, 0, 0);">170241061@qq.com</strong><span style="color: rgb(0, 0, 0);">与我们联系。一般情况下，我们会在15日内对您的请求予以答复。&nbsp;</span>
    </p>
    <p><br></p>
    <p><strong style="color: rgb(0, 0, 0);">十一、争议解决</strong></p>
    <p><span style="color: rgb(0, 0, 0);">本《个人信息保护政策》与《勤工助学用户协议及隐私政策》共同构成您使用勤工助学服务的基本协议文件。本《个人信息保护政策》适用中华人民共和国现行法律法规。与本《个人信息保护政策》相关的争议，如果您对我们的回复或解决方案不满意，需要通过法律途径解决的，应将该争议提交至北京仲裁委员会，其仲裁裁决是终局的。</span>
    </p>
    <p><strong style="color: rgb(0, 0, 0);">附录：定义</strong></p>
    <p><span style="color: rgb(0, 0, 0);">本《个人信息保护政策》中使用的特定词语，具有如下含义：</span></p>
    <p><span style="color: rgb(0, 0, 0);">1.“我们”或“钟信教育公司”，指厦门钟信教育科技有限公司。</span></p>
    <p><span style="color: rgb(0, 0, 0);">3.“您”或“用户”，指使用厦门钟信教育科技有限公司运营的平台产品或服务的注册用户以及收费服务的购买方。</span></p>
    <p><span style="color: rgb(0, 0, 0);">4.“勤工助学平台”或“勤工助学软件”，指勤工助学PC端及勤工助学手机客户端、勤工助学微信小程序。</span></p>
    <p><span style="color: rgb(0, 0, 0);">6.“个人信息”，指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。个人信息包括个人基本信息、个人身份信息、个人生物识别信息、网络身份标识信息、个人健康生理信息、个人教育工作信息、个人财产信息、个人通信信息、联系人信息、个人上网记录、个人常用设备信息、个人位置信息等。为免疑义，个人信息包括但不限于个人敏感信息。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">7.“个人信息主体”，指个人信息所标识的自然人。</span></p>
    <p><span style="color: rgb(0, 0, 0);">8.“个人敏感信息”，指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。个人敏感信息包括个人财产信息、个人健康生理信息、个人生物识别信息、个人身份信息、网络身份标识信息等。</span>
    </p>
    <p><span style="color: rgb(0, 0, 0);">9.“去标识化”，指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程。</span></p>
    <p><span style="color: rgb(0, 0, 0);">10.“匿名化”，指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。</span></p>
    <p><span style="color: rgb(0, 0, 0);">11.“中国”或“中国境内”，指中华人民共和国大陆地区，仅为本《个人信息保护政策》之目的，不包含香港特别行政区、澳门特别行政区和台湾地区。</span>
    </p>
  </a-layout-content>
</template>

<script>

export default {
  name: 'Privacy',
  created() {
    document.title = '《用户协议及隐私政策》'
  }
}
</script>
<style lang="scss">
.ql-align-center {
  text-align: center
}
</style>
