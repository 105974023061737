import request from '@/utils/request'

/**
 * 获取系统配置
 * @param key   sys.platform.serviceRate - 平台服务费率
 * @returns {AxiosPromise}
 */
export function getConfigKey(key) {
    return request({
        url: '/system/configs/key/' + key,
        method: 'get'
    })
}
