import request from '@/utils/request'

/**
 * 学校申请
 * @param id
 * @returns {AxiosPromise}
 */
export function addApplication(data) {
  return request({
    url: '/school/applications',
    method: 'post',
    data:data
  })
}
